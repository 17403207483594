import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_PLANNING } from "constants/endpoints"
import { Pagination } from "constants/types"
import { Cookies } from "react-cookie"

export type PlanningRequest = {
  p: number
  magasin?: number | ""
  personel?: number | ""
  date_start?: string
  date_end?: string
}

export type PlanningResponse = {
  date_debut: Date,
  date_fin: Date,
  point_vente_nom: string,
  point_vente: number
  si_planning_complet: boolean,
}

export type PlanningError = {
  detail: "Authentication credentials were not provided." | undefined
}

export default async function planning(params = {}) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_PLANNING,
    params: {
      si_paginated: "True",
      ...params,
    }
  })

  return response.data as Pagination<PlanningResponse>
}
