import { useQuery } from "@tanstack/react-query"
import documents, { DocumentsError, DocumentsRequest } from "api/documents/documents"
import { AxiosError } from "axios"
import { KEY_DOCUMENTS } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { stripEmptyKeys } from "utils/queryUtils"

export default function useDocumentsQuery(args: CustomQueryArgs<DocumentsRequest> = {}) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_DOCUMENTS, { paginated: true, ...strippedKey }] : [KEY_DOCUMENTS],
    queryFn: () => documents(strippedKey),
    onError: (err: AxiosError<DocumentsError>) => err,
    ...args.options
  })
}
