import "static/styles/app.scss"
import IndexRouter from "routes/IndexRouter"
import Toasty from "components/commun/Toasty"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ThemeProvider } from "@mui/material/styles"
import { CookiesProvider } from "react-cookie"
import queryConfig from "config/queryConfig"
import mafranchiseTheme from "config/muiConfig"
import useWatchNetwork from "hooks/useWatchNetwork"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import fr from "date-fns/locale/fr"
import { CssBaseline } from "@mui/material"
import ErrorFallback from "components/commun/ErrorFallback"
import { ProSidebarProvider } from "react-pro-sidebar"
import { ErrorBoundary } from "@sentry/react"

function App() {

  useWatchNetwork()

  return (
    <CookiesProvider>
      <QueryClientProvider client={queryConfig}>
        <ThemeProvider theme={mafranchiseTheme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <ErrorBoundary fallback={ErrorFallback}>
              <ProSidebarProvider>
                <IndexRouter />
              </ProSidebarProvider>
            </ErrorBoundary>
          </LocalizationProvider>
          <Toasty />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
        </ThemeProvider>
      </QueryClientProvider>
    </CookiesProvider>
  )
}

export default App
