import { useMutation, useQueryClient } from "@tanstack/react-query"
import refreshToken from "api/auth/refreshToken"
import { COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN } from "constants/cookies"
import { useCookies } from "react-cookie"

export default function useRefreshTokenMutation() {

  const [, setCookie] = useCookies([COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN])
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => refreshToken(),
    onSuccess: async (data) => {
      const cookieOptions = {
        path: "/",
        sameSite: true,
        secure: true,
      }

      const accessTokenAge = Number(process.env.REACT_APP_ACCESS_TOKEN_MAX_AGE)
      const refreshTokenAge = Number(process.env.REACT_APP_REFRESH_TOKEN_MAX_AGE)

      setCookie(COOKIE_ACCESS_TOKEN, data.access_token, { ...cookieOptions, maxAge: accessTokenAge })
      setCookie(COOKIE_REFRESH_TOKEN, data.refresh_token, { ...cookieOptions, maxAge: refreshTokenAge })

      await queryClient.invalidateQueries({ type: "all" })
    },
    retry: 3
  })
}
