import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"

type Count = {
  isSidebarCollapsed: boolean
  setIsSidebarCollapsed: (newState: boolean) => void
}

const usePreferencesStore = create<Count>()(immer(persist(devtools(set => ({
  isSidebarCollapsed: true,
  setIsSidebarCollapsed: (newState) => {
    set(() => ({ isSidebarCollapsed: newState }))
  },
})), { name: "preferences" })))

export default usePreferencesStore
