import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_PLANNING_DETAILS } from "constants/endpoints"
import { DaysOfTheWeek, MembreSubType } from "constants/types"
import { Cookies } from "react-cookie"

export type PlanningDetailsRequest = {
  date_debut: string
  date_fin: string
  magasin?: number | ""
}

export type PlanningDay = {
  id: 8,
  date_jour: Date
  jour: DaysOfTheWeek
  shift_matin_heure_debut: string | null
  shift_matin_heure_fin: string | null
  shift_apresmidi_heure_debut: string | null
  shift_apresmidi_heure_fin: string | null
  pointage_matin_heure_debut: string | null
  pointage_matin_heure_fin: string | null
  pointage_apresmidi_heure_debut: string | null
  pointage_apresmidi_heure_fin: string | null
  si_jour_repos: boolean
  type_repos: "absence" | "conge" | "repos" | "maladie" | null
  created_at: Date
}

export type PlanningDetailsResponse = {
  id: number
  membre: MembreSubType
  couleur_planing: string
  planing_jours: PlanningDay[]
}

export type PlanningDetailsError = {
  detail: "Authentication credentials were not provided." | undefined
}

export default async function planningDetails(params = {}) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_PLANNING_DETAILS,
    params: params
  })

  return response.data as PlanningDetailsResponse[]
}
