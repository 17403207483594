import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_VARIABLES } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type NowResponse = Date

export type NowError = {
  detail: "Authentication credentials were not provided." | undefined
}

export default async function now() {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "POST",
    url: API_VARIABLES,
    data: {
      name: "current_date"
    }
  })

  return new Date(response.data.value) as NowResponse
}
