import "static/styles/commun/header.scss"
import { IconButton, Stack } from "@mui/material"
import User from "./components/User"
import { useProSidebar } from "react-pro-sidebar"
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material"
import usePreferencesStore from "features/preferences"
import { useEffect } from "react"

export default function Header() {

  const { collapsed, collapseSidebar } = useProSidebar()
  const { isSidebarCollapsed, setIsSidebarCollapsed } = usePreferencesStore()

  useEffect(() => {
    collapseSidebar(isSidebarCollapsed)
  }, [])

  useEffect(() => {
    setIsSidebarCollapsed(collapsed)
  }, [collapsed])

  return (
    <header className="header">
      <div className="header-inner">
        <IconButton
          onClick={() => collapseSidebar()}
          title="Reduire / Agrandire la bare de navigation"
        >
          {collapsed ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
        </IconButton>
        <Stack flexDirection="row" alignItems="center">
          {/* <Notification /> */}
          <User />
        </Stack>
      </div>
    </header>
  )
}
