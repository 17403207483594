import { TextField } from "@mui/material"
import { DatePicker, PickersDay, PickersDayProps } from "@mui/x-date-pickers"
import { getFirstDayOfWeek, getLastDayOfWeek, isTheSameWeek } from "utils/datetimeUtils"

type Props = {
  planningStart: Date | null,
  setPlanningStart: (date: Date | null) => void,
  planningEnd: Date | null,
  setPlanningEnd: (date: Date | null) => void,
}

export default function WeekPicker({ planningStart, setPlanningStart, setPlanningEnd }: Props) {

  function handleSetPlanningWeek(date: Date | null) {
    if (date) {
      setPlanningStart(getFirstDayOfWeek(date))
      setPlanningEnd(getLastDayOfWeek(date))
    } else {
      setPlanningStart(null)
      setPlanningEnd(null)
    }
  }

  return (
    <DatePicker
      label="Semaine"
      value={planningStart}
      onChange={date => handleSetPlanningWeek(date)}
      renderDay={(day: Date, selectedDays: Date[], pickersDayProps: PickersDayProps<Date>) => {

        const isInSameWeek = isTheSameWeek(day, planningStart || new Date())

        if (isInSameWeek) {
          return (
            <PickersDay
              {...pickersDayProps}
              sx={{ backgroundColor: "primary.main", color: "primary.contrastText", ":hover": { backgroundColor: "primary.dark" } }}
            />
          )
        } else {
          return (
            <PickersDay
              {...pickersDayProps}
            />
          )
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          sx={{ width: 300 }}
          onKeyDown= {e => e.preventDefault()}
        />
      )}
    />
  )
}
