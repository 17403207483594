import { Pagination, Stack } from "@mui/material"

type Props = {
  page: number
  numberOfPages: number
  changePage: (value: number) => void
}

export default function MainPagination({ page, numberOfPages, changePage } : Props) {
  if (numberOfPages > 1) return (
    <div className="pagination">
      <Stack sx={{ my: 8 }} direction="row" justifyContent="flex-end">
        <Pagination
          color="primary"
          count={numberOfPages}
          page={page}
          onChange={(event, value) => changePage(value)}
        />
      </Stack>
    </div>
  )
  return null
}