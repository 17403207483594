import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_MAGASINS } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type MagasinByIdRequest = {
  id: number
}

export type MagasinByIdResponse = {
  id: number
  nom: string
  identifiant_point_vente: string
  numero_pdv: string
  ville: string
  nom_ville: string
  telephone: string
  adresse: string
  latitude: number
  longitude: number
  logo: string
  flag_map: string
  is_hidden: false
  created_at: Date
  updated_at: Date
}

export type MagasinByIdError = {
  detail: "Authentication credentials were not provided." | "Invalid page." | undefined
}

export default async function magasinById(params: MagasinByIdRequest = { id: 0 }) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: `${API_MAGASINS}${params.id}/`,
  })

  return response.data as MagasinByIdResponse
}
