import { ErrorOutline, ExpandMore, FastForwardOutlined } from "@mui/icons-material"
import ArrowRight from "@mui/icons-material/ArrowRightAlt"
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Divider, Stack, Typography } from "@mui/material"
import { URL_CREATE_PLANNING } from "constants/urls"
import usePlanningQuery from "hooks/queries/planning/usePlanningQuery"
import { useNavigate } from "react-router-dom"
import { isCurrentWeek, serverDateFormat, standardDateFormat } from "utils/datetimeUtils"
import PlanningDetails from "./components/PlanningDetails"
import usePagination from "hooks/usePagination"
import MainPagination from "components/commun/MainPagination"
import Loader from "components/commun/ui/Loader"
import useFilters from "hooks/useFilters"
import MainFilter from "components/commun/MainFilter"
import MFCard from "components/commun/ui/MFCard"

export default function Planning() {

  const navigate = useNavigate()
  const pagination = usePagination(1)

  const {
    magasinFilter,
    membreFilter,
    dateStartFilter,
    dateEndFilter,
  } = useFilters(() => pagination.changePage(1))

  const planningQuery = usePlanningQuery({
    key: {
      p: pagination.page,
      magasin: Number(magasinFilter.value.id) || "",
      personel: Number(membreFilter.value.id) || "",
      date_start: serverDateFormat(dateStartFilter.value) || "",
      date_end: serverDateFormat(dateEndFilter.value) || "",
    }
  })

  function PlanningsList() {
    if (planningQuery.isLoading) {
      return <Loader />
    }

    if (planningQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
          sx={{ mt: 8 }}
        >
          Une erreur est survenue
        </Alert>
      )
    }

    if (planningQuery.data.count === 0) {
      return (
        <Alert
          severity="warning"
          variant="standard"
          sx={{ mt: 8 }}
        >
        La liste des plannings est vide
        </Alert>
      )
    }

    return (
      <>
        {planningQuery.data?.results.map((planning, index) => (
          <Accordion key={index} TransitionProps={{ unmountOnExit: true }} sx={{ my: 4 }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <Stack direction="row" alignItems="center">
                  {!planning.si_planning_complet && (
                    <ErrorOutline titleAccess="Ce planning n'est pas complet, vous devez creer les plannings de tous les membres de ce magasin" color="error" fontSize="small" sx={{ mr: 1 }} />
                  )}
                  {planning.point_vente_nom}
                  {isCurrentWeek(planning.date_debut) && (
                    <FastForwardOutlined titleAccess="La semaine actuelle" fontSize="small" htmlColor="#666" sx={{ ml: 1 }} />
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" mr={4}>
                  <Typography fontSize="0.9rem">{standardDateFormat(new Date(planning.date_debut))}</Typography>
                  <ArrowRight color="action" />
                  <Typography fontSize="0.9rem">{standardDateFormat(new Date(planning.date_fin))}</Typography>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Divider sx={{ mb: 4 }} />
              <PlanningDetails
                magasinId={planning.point_vente}
                startDate={serverDateFormat(planning.date_debut)}
                endDate={serverDateFormat(planning.date_fin)}
              />
            </AccordionDetails>
          </Accordion>
        ))}

        <MainPagination
          page={pagination.page}
          numberOfPages={pagination.countPages(planningQuery.data.count, planningQuery.data.count_per_page)}
          changePage={pagination.changePage}
        />
      </>
    )
  }

  return (
    <Box>
      <Stack direction="row" justifyContent="flex-end" spacing={4} mb={6}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(URL_CREATE_PLANNING)}
        >
          Créer un nouveau planning
        </Button>
      </Stack>

      <MFCard variant="outlined">
        <Stack spacing={2} direction="row" m={6}>
          <MainFilter
            magasinFilter={magasinFilter}
            membreFilter={membreFilter}
            dateStartFilter={dateStartFilter}
            dateEndFilter={dateEndFilter}
          />
        </Stack>
      </MFCard>

      {PlanningsList()}
    </Box>
  )
}
