import { useQuery } from "@tanstack/react-query"
import now from "api/variables/now"
import { KEY_NOW } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"

export default function useNowQuery(args: CustomQueryArgs<undefined> = {}) {
  return useQuery({
    queryKey: [KEY_NOW],
    queryFn: () => now(),
    refetchInterval: 1000 * 60 * 5 /* 5 minutes */,
    ...args.options
  })
}
