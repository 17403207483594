import { Avatar, Chip, Stack, Typography } from "@mui/material"
import ArrowRight from "@mui/icons-material/ArrowRightAlt"

type Props = {
  membre: {
    first_name: string
    last_name: string
    fonction: string
    photo: string
  },
  status: "retard" | "absence" | "maladie" | "conge"
  startDate?: string | undefined
  endDate?: string | undefined
  lateTime?: number
}

export default function UserStatusCard({ membre, status, lateTime, startDate, endDate }: Props) {

  function _statusChip() {
    switch (status) {
    case "retard":
      return (
        <Chip
          label="retard"
          variant="outlined"
          style={{ backgroundColor: "#F7F4E5", borderColor: "#FFDBA5", color: "#FBCB73" }}
        />
      )
    case "conge":
      return (
        <Chip
          label="congé"
          variant="outlined"
          style={{ backgroundColor: "#E5F2F7", borderColor: "#A3EBE7", color: "#6F97BA" }}
        />
      )
    case "maladie":
      return (
        <Chip
          label="maladie"
          variant="outlined"
          style={{ backgroundColor: "#FFEAEC", borderColor: "#E6AEB5", color: "#F0806E" }}
        />
      )
    case "absence":
      return (
        <Chip
          label="absence"
          variant="outlined"
          style={{ backgroundColor: "#E5F2F7", borderColor: "#A3EBE7", color: "#6F97BA" }}
        />
      )
    default:
      return null
    }
  }

  function _info() {
    if (status === "retard") {
      return (
        <Chip
          className="date-chip"
          label={`Retard de ${lateTime} min`}
          variant="outlined"
        />
      )
    } else if (status === "absence") {
      return (
        <Stack direction="row" alignItems="center">
          <Chip
            className="date-chip"
            label={startDate ? startDate : "..."}
            variant="outlined"
          />

          <ArrowRight color="action" />

          <Chip
            className="date-chip"
            label={endDate ? endDate : "..."}
            variant="outlined"
          />
        </Stack>
      )
    }

    return null
  }

  return (
    <div className="user-status-card">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Avatar src={membre.photo} sx={{ mr: 2 }} />
          <Stack direction="column" alignItems="flex-start" marginLeft={2}>
            <Typography variant="subtitle2">{membre.first_name + " " + membre.last_name}</Typography>
            <Typography variant="caption" color="#666666" fontWeight={300}>{membre.fonction || "-"}</Typography>
          </Stack>
        </Stack>

        <Stack direction="column" alignItems="flex-end" justifyContent="space-between" spacing={4}>
          {_statusChip()}
          {_info()}
        </Stack>
      </Stack>
    </div>
  )
}
