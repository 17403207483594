import { Route, Routes } from "react-router-dom"
import PublicRoutes from "./PublicRoutes"
import PrivateRoutes from "./PrivateRoutes"
import Login from "components/auth/Login"
import Dashboard from "components/dashboard/Dashboard"
import NotFound from "components/commun/NotFound"
import Personnels from "components/personnels/Personnels"
import Conges from "components/conges/Conges"
import Documents from "components/documents/Documents"
import Magasins from "components/magasins/Magasins"
import Achats from "components/achats/Achats"
import Loader from "components/commun/ui/Loader"
import Planning from "components/planning/Planning"
import { URL_ABSENCES, URL_ACHATS, URL_CONGES, URL_CREATE_PLANNING, URL_DASHBOARD, URL_DOCUMENTS, URL_LOGIN, URL_MAGASINS, URL_NOTIFICATIONS, URL_PERSONNELS, URL_PLANNING, URL_RETARDS, URL_SEND_NOTIFICATIONS } from "constants/urls"
import { Backdrop } from "@mui/material"
import { ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER } from "constants/roles"
import useInfoProfileQuery from "hooks/queries/auth/useInfoProfileQuery"
import Notifications from "components/notifications/Notifications"
import CreatePlanning from "components/planning/CreatePlanning"
import SendNotifications from "components/notifications/SendNotification"
import Retards from "components/assiduite/Retards"
import Absences from "components/assiduite/Absences"

export default function IndexRouter() {

  const userInfoQuery = useInfoProfileQuery()

  // We cannot use userInfoQuery.isLoading here because the query is initially disabled
  // hence it will always be loading
  if (userInfoQuery.isInitialLoading) {
    return (
      <Backdrop open={true}>
        <Loader />
      </Backdrop>
    )
  }

  // The routes (Public and Private) will redirect the user accordingly to their auth state
  return (
    <Routes>
      <Route element={<PublicRoutes isAuth={!!userInfoQuery.data} />}>
        <Route path={URL_LOGIN} element={<Login />} />
      </Route>

      <Route element={<PrivateRoutes
        isAuth={!!userInfoQuery.data}
        userRole={userInfoQuery.data?.type_profile}
        allowedRoles={[ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER]} />
      }>
        <Route path={URL_DASHBOARD} element={<Dashboard />} />
        <Route path={URL_PLANNING} element={<Planning />} />
        <Route path={URL_CREATE_PLANNING} element={<CreatePlanning />} />
        <Route path={URL_MAGASINS} element={<Magasins />} />
        <Route path={URL_PERSONNELS} element={<Personnels />} />
        <Route path={URL_RETARDS} element={<Retards />} />
        <Route path={URL_ABSENCES} element={<Absences />} />
        <Route path={URL_CONGES} element={<Conges />} />
        <Route path={URL_DOCUMENTS} element={<Documents />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route element={<PrivateRoutes
        isAuth={!!userInfoQuery.data}
        userRole={userInfoQuery.data?.type_profile}
        allowedRoles={[ROLE_RESPONSABLE, ROLE_SUPERUSER]} />
      }>
        <Route path={URL_ACHATS} element={<Achats />} />
        <Route path={URL_NOTIFICATIONS} element={<Notifications />} />
        <Route path={URL_SEND_NOTIFICATIONS} element={<SendNotifications />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}
