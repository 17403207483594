import { onlineManager } from "@tanstack/react-query"
import { toast } from "react-hot-toast"

/**
 * Subscribe to the network state of the app
 * and show an app-level toast accordingly
 */
export default function useWatchNetwork() {
  onlineManager.subscribe(() => {
    const isOnline = onlineManager.isOnline()

    if (isOnline) {
      toast.success("Connexion rétablie!", {
        id: "networkToast",
      })
    } else {
      toast.loading("Tentative de reconnexion..", {
        id: "networkToast",
      })
    }
  })
}
