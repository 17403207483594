import axios from "axios"
import { COOKIE_CLIENT_ID, COOKIE_CLIENT_SECRET, COOKIE_REFRESH_TOKEN } from "constants/cookies"
import { API_REFRESH_TOKEN } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type RefreshTokenResponse = {
  access_token: string
  refresh_token: string
}

export default async function refreshToken() {

  const cookies = new Cookies()

  const response = await axios({
    method: "POST",
    url: API_REFRESH_TOKEN,
    data: {
      refresh_token: cookies.get(COOKIE_REFRESH_TOKEN),
      client_secret: cookies.get(COOKIE_CLIENT_SECRET),
      client_id: cookies.get(COOKIE_CLIENT_ID),
    }
  })

  return response.data as RefreshTokenResponse
}
