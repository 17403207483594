import { Navigate, Outlet, useLocation } from "react-router-dom"
import { Box } from "@mui/material"
import { URL_DASHBOARD, URL_LOGIN } from "constants/urls"
import Sidebar from "components/commun/Sidebar"
import Header from "components/commun/header/Header"

type Props = {
  isAuth: boolean
  userRole?: string
  allowedRoles: string[]
}

export default function PrivateRoutes({ isAuth, userRole, allowedRoles }: Props) {
  const location = useLocation()

  if (!isAuth) {
    return <Navigate to={URL_LOGIN} state={{ from: location }} replace />
  }

  if (!allowedRoles.includes(userRole as string)) {
    return <Navigate to={URL_DASHBOARD} state={{ from: location }} replace />
  }

  return (
    <div className="app">
      <Sidebar />

      <div className="content">
        <Header />

        <Box sx={{ m: 6 }}>
          <Outlet />
        </Box>
      </div>
    </div>
  )
}
