import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_MARQUES } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type MarquesResponse = {
  id: number
  nom: string
  identifiant_marque: string
  image_color_base64: string
  image_black_base64: string
  description: string
  category: string
  color_marque: string
  created_at: Date
  updated_at: Date
  shopping_url: string
  is_hidden: boolean
}

export type MarquesError = {
  detail: "Authentication credentials were not provided." | undefined
}

export default async function marques() {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_MARQUES,
    params: {
      restricted: "True"
    }
  })

  return response.data as MarquesResponse[]
}
