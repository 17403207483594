import { useQueryClient, useMutation } from "@tanstack/react-query"
import createPlanning, { CreatePlanningError, CreatePlanningRequest } from "api/planning/createPlanning"
import { AxiosError } from "axios"
import { KEY_PLANNING, KEY_PLANNING_DETAILS } from "constants/queryKeys"
import toast from "react-hot-toast"

export default function useCreatePlanningMutation() {

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: CreatePlanningRequest) => createPlanning(data),
    onMutate: () => {
      toast.loading("Création du planning en cours..", {
        id: "createPlanning",
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [KEY_PLANNING] })
      await queryClient.invalidateQueries({ queryKey: [KEY_PLANNING_DETAILS] })
      toast.success("Planning crée avec succès", {
        id: "createPlanning",
      })
    },
    onError: (err: AxiosError<CreatePlanningError>) => {
      if (err.response?.data.planning_duplication_error) {
        toast.error("Un planning existe déjà pour cette période", {
          id: "createPlanning",
        })
      } else if (err.response?.data.semaine_started_error) {
        toast.error("Vous ne pouvez pas modifier un planning déjà en cours", {
          id: "createPlanning",
        })
      } else {
        toast.error("Une erreur est survenue lors de la création du planning", {
          id: "createPlanning",
        })
      }
    },
  })
}
