import "static/styles/commun/toasty.scss"
import { Toaster } from "react-hot-toast"

export default function Toasty() {
  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        className: "toasty",
        duration: 5000,
        loading: {
          duration: 60000
        }
      }}
    />
  )
}
