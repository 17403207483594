export type CreatePlanningBlockInput = {
  label: string
  stack: string
  backgroundColor: any
  data: [number, number][]
}

export function createPresenceBlock({ label, stack, backgroundColor, data }: CreatePlanningBlockInput) {
  const presenceBlock = {
    label: label,
    data: data,
    backgroundColor: backgroundColor,
    stack: stack,
    borderWidth: {
      right: 6,
      left: 6,
    },
    borderColor: "transparent",
  }

  return presenceBlock
}

export function createPlannedBlock({ label, stack, backgroundColor, data }: CreatePlanningBlockInput) {
  const plannedBlock = {
    label: label,
    data: data,
    backgroundColor: backgroundColor,
    stack: stack,
  }

  return plannedBlock
}
