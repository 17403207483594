import { format, milliseconds, isValid, addDays, startOfWeek, endOfWeek, isThisWeek, isSameWeek } from "date-fns"
import fr from "date-fns/locale/fr"
import { capitalize } from "lodash"

// takes a date and return it in a standard date format
export function standardDateFormat(date: Date): string {
  return format(new Date(date), "dd/MM/yyyy")
}

// takes a date and return it in a standard time format
export function standardTimeFormat(date: Date): string {
  return format(new Date(date), "HH:mm")
}

// takes a date and return it in the server date format
export function serverDateFormat(date: Date | null): string {
  if (date === null) return ""
  return format(new Date(date), "yyyy-MM-dd")
}

// takes a date and return it in the server date format
export function serverTimeFormat(date: Date | null): string {
  if (date === null) return ""
  return format(new Date(date), "HH:mm:ss")
}

// takes planning time and return it in milliseconds (09:30:00 => 34200000)
export function planningTimeToMs(time: string): number {
  const [hours, minutes, seconds] = time.split(":")
  return milliseconds({
    hours: parseInt(hours),
    minutes: parseInt(minutes),
    seconds: parseInt(seconds)
  })
}

// takes milliseconds and return it in planning time (34200000 => 09:30:00)
export function msToPlanningTime(time: number): string {
  return format(time, "HH:mm")
}

// takes a date and returns the name of the day
export function getNameOfDay(date: Date | null): string {
  if (isValid(date)) {
    const dayName = format(date || new Date(), "EEEE", { locale: fr })
    return capitalize(dayName)
  }
  return ""
}

export function getFirstDayOfWeek(date: Date = new Date()): Date {
  return startOfWeek(date, { weekStartsOn: 1 })
}

export function getLastDayOfWeek(date: Date = new Date()): Date {
  return endOfWeek(date, { weekStartsOn: 1 })
}

export function getFirstDayOfNextWeek(): Date {
  return startOfWeek(addDays(new Date(), 7), { weekStartsOn: 1 })
}

export function getLastDayOfNextWeek(): Date {
  return endOfWeek(addDays(new Date(), 7), { weekStartsOn: 1 })
}

export function getFirstDayOfPreviousWeek(): Date {
  return startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 })
}

export function getLastDayOfPreviousWeek(): Date {
  return endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 })
}

export function isCurrentWeek(date: Date = new Date()): boolean {
  return isThisWeek(new Date(date), { weekStartsOn: 1 })
}

export function isNextWeek(date: Date = new Date()): boolean {
  return isSameWeek(date, getFirstDayOfNextWeek(), { weekStartsOn: 1 })
}

export function isPreviousWeek(date: Date = new Date()): boolean {
  return isSameWeek(date, getFirstDayOfPreviousWeek(), { weekStartsOn: 1 })
}

export function isTheSameWeek(date1: Date = new Date(), date2: Date = new Date()): boolean {
  return isSameWeek(date1, date2, { weekStartsOn: 1 })
}
