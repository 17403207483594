import { Typography, Stack } from "@mui/material"
import MFCard from "components/commun/ui/MFCard"
import { ReactNode } from "react"

type Props = {
  icon: ReactNode
  title: string
  count: number
  color: string
}

export const Action = ({ icon, title, count, color }: Props) => {
  return (
    <MFCard variant="outlined" clickable>
      <Stack className="action" alignItems="flex-start" justifyContent="center" sx={{ p: 4 }}>
        <Stack spacing={4} direction="row" alignItems="center" justifyContent="flex-start" sx={{ minHeight: 90 }}>
          <div style={{ backgroundColor: `${color}22`, padding: 15, borderRadius: 5 }}>
            {icon}
          </div>
          <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
            <Typography variant="h4" color={color} fontWeight={700}>{count}</Typography>
            <Typography variant="subtitle2" color="black" textAlign="start">{title}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </MFCard>
  )
}
