import { Clear, Done } from "@mui/icons-material"
import { Stack } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

type Props = {
  isOpen: boolean
  closeFn: () => void
  title: string
  content: string
  agree: {
    text: string
    fn: () => void
  },
  disagree: {
    text: string
    fn: () => void
  }
}

export default function MainDialog({ isOpen, closeFn, title, content, agree, disagree }: Props) {

  const handleClose = (decision: "agree" | "disagree") => {
    closeFn()
    decision === "agree" ? agree.fn() : disagree.fn()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={5} mt={5}>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleClose("disagree")}
          >
            <Clear fontSize="small" sx={{ mr: 1.5 }} />
            {disagree.text}
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleClose("agree")}
          >
            <Done fontSize="small" sx={{ mr: 1.5 }} />
            {agree.text}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
