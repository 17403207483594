import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_MEMBRES } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type MembreByIdRequest = {
  id: number
}

export type MembreByIdResponse = {
  id: number
  identifiant: string
  telephone: string
  last_name: string
  first_name: string
  adresse: string
  ville: string
  ville_nom: string
  cin: string
  document_cin: string
  cnss: string
  date_naissance: Date
  date_embauche: Date
  poste: string
  poste_nom: string
  magasin: string
  magasin_nom: string
  responsable: string
  date_depart: Date
  statut: string
  situation_familiale: string
  photo: string
  created_at: Date
  updated_at: Date
}

export type MembreByIdError = {
  detail: "Authentication credentials were not provided." | "Invalid page." | undefined
}

export default async function membreById(params: MembreByIdRequest = { id: 0 }) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: `${API_MEMBRES}${params.id}/`,
  })

  return response.data as MembreByIdResponse
}
