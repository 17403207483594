import "static/styles/magasins.scss"
import { useDocumentTitle } from "usehooks-ts"
import usePagination from "hooks/usePagination"
import Loader from "components/commun/ui/Loader"
import { Alert, Box, Grid, Stack } from "@mui/material"
import MagasinCard from "./components/MagasinCard"
import MainPagination from "components/commun/MainPagination"
import useMagasinsQuery from "hooks/queries/magasins/useMagasinsQuery"
import MainFilter from "components/commun/MainFilter"
import useFilters from "hooks/useFilters"
import MFCard from "components/commun/ui/MFCard"

export default function Magasins() {

  useDocumentTitle("Mafranchise | Magasins")

  const pagination = usePagination(1)
  const {
    villeFilter,
    marqueFilter
  } = useFilters(() => pagination.changePage(1))

  const magasinsQuery = useMagasinsQuery({
    key: {
      p: pagination.page,
      ville: Number(villeFilter.value.id) || "",
      marque: Number(marqueFilter.value.id) || "",
    }
  })

  function MagasinsList() {
    if (magasinsQuery.isLoading) {
      return <Loader />
    }

    if (magasinsQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
          sx={{ mt: 8 }}
        >
          Une erreur est survenue
        </Alert>
      )
    }

    if (magasinsQuery.data.count === 0) {
      return (
        <Alert
          severity="warning"
          variant="standard"
          sx={{ mt: 8 }}
        >
          La liste des magasins est vide
        </Alert>
      )
    }

    return (
      <>
        <Grid container spacing={10} mt={0}>
          {magasinsQuery.data.results.map(magasin => {
            return (
              <Grid item xs={4} key={magasin.id}>
                <MagasinCard magasin={magasin} />
              </Grid>
            )
          })}
        </Grid>

        <MainPagination
          page={pagination.page}
          numberOfPages={pagination.countPages(magasinsQuery.data.count, magasinsQuery.data.count_per_page)}
          changePage={pagination.changePage}
        />
      </>
    )
  }

  return (
    <Box>
      <MFCard variant="outlined">
        <Stack spacing={2} direction="row" m={6}>
          <MainFilter
            villeFilter={villeFilter}
            marqueFilter={marqueFilter}
          />
        </Stack>
      </MFCard>

      {MagasinsList()}
    </Box>
  )
}
