import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { getFirstDayOfNextWeek } from "utils/datetimeUtils"
import { addDays } from "date-fns"

/**
 * Only store membreId + planningId if we want to update the planning of a member
 * Otherwise, store the planning in the state of the component
 *
 * if we don't have a membreId in store, we should display a select to choose a member in order to create a new planning for this member
 *
 * if we have a membreId in store, we should not display a select to choose a member (only display the name of the member)
 * And we should change the text of the button "Publier le planning" to "Modifier le planning"
 *
 * If the user quits the page, we should remove the membreId from the store on unmout the component
 */

type DateOrNull = Date | null

export type PlanDay = {
  date: DateOrNull
  shift1Start: DateOrNull
  shift1End: DateOrNull
  shift2Start: DateOrNull
  shift2End: DateOrNull
  isDayOff: boolean
  dayOffType: string | null
}

type Planning = {
  planningId: number
  membreId: number
  planDays: PlanDay[]

  setPlanningId: (id: number) => void
  setMembreId: (id: number) => void
  setShift1Start: (index: number, newShift1Start: DateOrNull) => void
  setShift1End: (index: number, newShift1End: DateOrNull) => void
  setShift2Start: (index: number, newShift2Start: DateOrNull) => void
  setShift2End: (index: number, newShift2End: DateOrNull) => void
  setDayOff: (index: number, si_jour_repos: boolean) => void
  setDayOffType: (index: number, type: string | null) => void

  initDays: () => void
  clone: (planning: {
    shift1Start: DateOrNull
    shift1End: DateOrNull
    shift2Start: DateOrNull
    shift2End: DateOrNull
    isDayOff: boolean
    dayOffType: string | null
    date: DateOrNull
  }[]) => void
  emptyDay: (index: number) => void
  reset: () => void
}

const initialState: PlanDay = {
  date: null,
  shift1Start: null,
  shift1End: null,
  shift2Start: null,
  shift2End: null,
  isDayOff: false,
  dayOffType: null
}

const usePlanStore = create<Planning>()(immer(persist(devtools(set => ({
  planningId: 0,
  membreId: 0,
  planDays: [...Array(7)].map(() => initialState),

  setPlanningId: (id) => {
    set(state => {
      state.planningId = id
      return state
    })
  },

  setMembreId: (id) => {
    set(state => {
      state.membreId = id
      return state
    })
  },

  setShift1Start: (index, newShift1Start) => {
    set(state => {
      state.planDays[index].shift1Start = newShift1Start
      return state
    })
  },

  setShift1End: (index, newShift1End) => {
    set(state => {
      state.planDays[index].shift1End = newShift1End
      return state
    })
  },

  setShift2Start: (index, newShift2Start) => {
    set(state => {
      state.planDays[index].shift2Start = newShift2Start
      return state
    })
  },

  setShift2End: (index, newShift2End) => {
    set(state => {
      state.planDays[index].shift2End = newShift2End
      return state
    })
  },

  setDayOff: (index, isDayOff) => {
    set(state => {
      state.planDays[index].isDayOff = isDayOff
      return state
    })
  },

  setDayOffType: (index, type) => {
    set(state => {
      state.planDays[index].dayOffType = type
      return state
    })
  },

  initDays: () => {
    set(state => {
      state.planDays = [...Array(7)].map((_, index) => {
        return {
          ...state.planDays[index],
          date: addDays(getFirstDayOfNextWeek(), index)
        }
      })
      return state
    })
  },

  clone: (planning) => {
    set(state => {
      state.planDays = [...Array(7)].map((_, index) => {
        return {
          ...state.planDays[index],
          shift1Start: planning[index].shift1Start,
          shift1End: planning[index].shift1End,
          shift2Start: planning[index].shift2Start,
          shift2End: planning[index].shift2End,
          isDayOff: planning[index].isDayOff,
          dayOffType: planning[index].dayOffType,
          date: planning[index].date
        }
      })
      return state
    })
  },

  emptyDay: (index) => {
    set(state => {
      state.planDays[index] = {
        ...initialState,
        isDayOff: state.planDays[index].isDayOff,
        dayOffType: state.planDays[index].dayOffType,
        date: state.planDays[index].date
      }
      return state
    })
  },

  reset: () => {
    set(state => {
      state.planningId = 0
      state.membreId = 0
      state.planDays = [...Array(7)].map((_, index) => {
        return {
          ...initialState,
          date: addDays(getFirstDayOfNextWeek(), index)
        }
      })
      return state
    })
  },
})), { name: "planning" })))

export default usePlanStore
