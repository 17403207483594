import { Grid } from "@mui/material"
import { URL_RETARDS, URL_ABSENCES, URL_CONGES, URL_DOCUMENTS } from "constants/urls"
import { Link } from "react-router-dom"
import { Action } from "./Action"
import { HourglassBottom, HourglassDisabled, Luggage, TextSnippet } from "@mui/icons-material/"
import useDocumentsQuery from "hooks/queries/documents/useDocumentsQuery"
import useAbsencesQuery from "hooks/queries/assiduite/useAbsencesQuery"
import useRetardsQuery from "hooks/queries/assiduite/useRetardsQuery"
import useCongesQuery from "hooks/queries/conges/useCongesQuery"
import { getFirstDayOfWeek, getLastDayOfWeek, serverDateFormat } from "utils/datetimeUtils"

export default function Actions() {

  const documentsQuery = useDocumentsQuery({
    key: {
      statut: "en_attente",
    }
  })

  const congesQuery = useCongesQuery({
    key: {
      statut: "en_attente"
    }
  })

  const retardsQuery = useRetardsQuery({
    key: {
      date_start: serverDateFormat(getFirstDayOfWeek()),
      date_end: serverDateFormat(getLastDayOfWeek()),
    }
  })

  const absencesQuery = useAbsencesQuery({
    key: {
      statut: "en_attente",
      date_start: serverDateFormat(getFirstDayOfWeek()),
      date_end: serverDateFormat(getLastDayOfWeek()),
    }
  })

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <Link to={URL_DOCUMENTS}>
          <Action
            icon={<TextSnippet htmlColor="#2487F2" />}
            title="Demandes administratives"
            count={documentsQuery.data?.count || 0}
            color="#2487F2"
          />
        </Link>
      </Grid>

      <Grid item xs={3}>
        <Link to={URL_CONGES}>
          <Action
            icon={<Luggage htmlColor="#00D0C4" />}
            title="Demandes de congés"
            count={congesQuery.data?.count || 0}
            color="#00D0C4"
          />
        </Link>
      </Grid>

      <Grid item xs={3}>
        <Link to={URL_RETARDS}>
          <Action
            icon={<HourglassBottom htmlColor="#7C47F2" />}
            title="Alertes de retards"
            count={retardsQuery.data?.count || 0}
            color="#7C47F2"
          />
        </Link>
      </Grid>

      <Grid item xs={3}>
        <Link to={URL_ABSENCES}>
          <Action
            icon={<HourglassDisabled htmlColor="#D84D4D" />}
            title="Alertes d'absences"
            count={absencesQuery.data?.count || 0}
            color="#D84D4D"
          />
        </Link>
      </Grid>
    </Grid>
  )
}
