import { QueryClient } from "@tanstack/query-core"
import { AxiosError } from "axios"
import { toast } from "react-hot-toast"

const queryConfig = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: Number(process.env.REACT_APP_QUERY_STALE_TIME) * 1000,
      cacheTime: Number(process.env.REACT_APP_QUERY_CACHE_TIME) * 1000,
      onError: (err: AxiosError | any) => {
        if (err instanceof AxiosError) {
          if (err.response?.status === 500) {
            toast.error("Une erreur est survenue", {
              id: "globalError"
            })
          } else if (err.response?.status === 401) {
            toast.error("Veuillez vous connecter", {
              id: "globalError"
            })
          } else if (err.response?.status === 404) {
            toast.error("La ressource demandée n'existe pas", {
              id: "globalError"
            })
          }
        }
      },
    },
    mutations: {
      retry: false,
      onError(err: AxiosError | any) {
        if (err.response?.status === 500) {
          toast.error("Une erreur est survenue", {
            id: "globalError"
          })
        } else if (err.response?.status === 401) {
          toast.error("Vous n'êtes pas autorisé à effectuer cette action", {
            id: "globalError"
          })
        }
      },
    },
  }
})

export default queryConfig
