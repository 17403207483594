import { Work } from "@mui/icons-material"
import { Alert, Avatar, Button, Grid, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { PlanningDetailsResponse } from "api/planning/planningDetails"
import Loader from "components/commun/ui/Loader"
import MembrePopup from "components/personnels/components/MembrePopup"
import { URL_CREATE_PLANNING } from "constants/urls"
import isBefore from "date-fns/isBefore"
import usePlanStore from "features/planning"
import usePlanningDetailsQuery from "hooks/queries/planning/usePlanningDetailsQuery"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"

type Props = {
  magasinId: number
  startDate: string
  endDate: string
}

export default function PlanningDetails({ magasinId, startDate, endDate }: Props) {

  const navigate = useNavigate()

  const {
    setPlanningId,
    setMembreId,
    clone
  } = usePlanStore()

  const planningDetailsQuery = usePlanningDetailsQuery({
    key: {
      magasin: magasinId,
      date_debut: startDate,
      date_fin: endDate
    }
  })

  function handleDuplicate(planning: PlanningDetailsResponse) {
    const cloneObject = planning.planing_jours.map(planningJour => ({
      shift1Start: new Date("01/01/1970" + " " + planningJour.shift_matin_heure_debut),
      shift1End: new Date("01/01/1970" + " " + planningJour.shift_matin_heure_fin),
      shift2Start: new Date("01/01/1970" + " " + planningJour.shift_apresmidi_heure_debut),
      shift2End: new Date("01/01/1970" + " " + planningJour.shift_apresmidi_heure_fin),
      isDayOff: planningJour.si_jour_repos,
      dayOffType: planningJour.type_repos,
      date: planningJour.date_jour
    }))

    clone(cloneObject)

    toast.success("Planning dupliqué avec succès")
    navigate(URL_CREATE_PLANNING)
  }

  function handleUpdate(membreId: number, planning: PlanningDetailsResponse) {
    const cloneObject = planning.planing_jours.map(planningJour => ({
      shift1Start: new Date("01/01/1970" + " " + planningJour.shift_matin_heure_debut),
      shift1End: new Date("01/01/1970" + " " + planningJour.shift_matin_heure_fin),
      shift2Start: new Date("01/01/1970" + " " + planningJour.shift_apresmidi_heure_debut),
      shift2End: new Date("01/01/1970" + " " + planningJour.shift_apresmidi_heure_fin),
      isDayOff: planningJour.si_jour_repos,
      dayOffType: planningJour.type_repos,
      date: planningJour.date_jour
    }))

    setPlanningId(planning.id)
    setMembreId(membreId)
    clone(cloneObject)

    navigate(URL_CREATE_PLANNING)
  }

  if (planningDetailsQuery.isLoading) {
    return <Loader />
  }

  if (planningDetailsQuery.isError) {
    return (
      <Alert
        severity="error"
        variant="standard"
      >
        Une erreur est survenue
      </Alert>
    )
  }

  if (planningDetailsQuery.data.length === 0) {
    return (
      <Alert
        severity="warning"
        variant="standard"
        sx={{ mt: 8 }}
      >
        La liste des plannings est vide
      </Alert>
    )
  }

  return (
    <Grid container spacing={4}>
      {planningDetailsQuery.data?.map(planning => (
        <Grid item xs={4} key={planning.id}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ p: 3, backgroundColor: "#F6F9FA", borderRadius: "10px", border: "1px solid #E7E9EF" }}
          >
            <MembrePopup membreId={planning.membre.id}>
              <Stack direction="row" alignItems="center">
                <Avatar src={planning.membre.photo} sx={{ mr: 2 }} />
                <Stack direction="column" alignItems="flex-start">
                  <Typography fontSize="1rem">{planning.membre.first_name + " " + planning.membre.last_name}</Typography>
                  <Stack direction="row" alignItems="center">
                    <Work htmlColor="#666666" sx={{ fontSize: "0.9rem", mr: 1 }} />
                    <Typography fontSize="0.8rem" color="#666666">{planning.membre.poste_nom || "-"}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </MembrePopup>
            <Stack direction="row" sx={{ borderLeft: "1px solid #E7E9EF", pl: 2 }}>
              <Button
                color="success"
                onClick={() => handleDuplicate(planning)}
              >
                Dupliquer
              </Button>
              {!isBefore(new Date(endDate), new Date()) && (
                <Button
                  color="info"
                  onClick={() => handleUpdate(planning.membre.id, planning)}
                >
                  Modifier
                </Button>
              )}
            </Stack>
          </Stack>
        </Grid>
      ))}
    </Grid>
  )
}
