import { useQuery } from "@tanstack/react-query"
import infoProfile, { InfoProfileError } from "api/auth/infoProfile"
import { AxiosError } from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { KEY_INFO_PROFILE } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { useCookies } from "react-cookie"
import useRefreshTokenMutation from "./useRefreshTokenMutation"

export default function useInfoProfileQuery(args: CustomQueryArgs<undefined> = {}) {

  const [cookies] = useCookies([COOKIE_ACCESS_TOKEN])

  const refreshTokenMutation = useRefreshTokenMutation()

  return useQuery({
    queryKey: [KEY_INFO_PROFILE],
    queryFn: () => infoProfile(),
    enabled: !!cookies[COOKIE_ACCESS_TOKEN],
    onError: (err: AxiosError<InfoProfileError>) => {
      // refresh token if access token is expired or invalid
      if (err.response?.status === 401) {
        refreshTokenMutation.mutate()
      }
    },
    ...args.options
  })
}
