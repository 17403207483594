export const URL_LOGIN = "/login"
export const URL_DASHBOARD = "/"

// Planning
export const URL_PLANNING = "/planning"
export const URL_CREATE_PLANNING = "/planning/create"

export const URL_MAGASINS = "/magasins"
export const URL_PERSONNELS = "/personnels"
export const URL_ACHATS = "/achats"

export const URL_ASSIDUITE = "/assiduite"
export const URL_RETARDS = "/assiduite/retards"
export const URL_ABSENCES = "/assiduite/absences"

export const URL_NOTIFICATIONS = "/notifications"
export const URL_SEND_NOTIFICATIONS = "/notifications/send"

export const URL_CONGES = "/conges"
export const URL_DOCUMENTS = "/documents"
