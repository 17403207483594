import { Alert, Box, Stack } from "@mui/material"
import { BarElement, CategoryScale, Chart as ChartJS, ChartType, InteractionMode, Legend, LinearScale, TimeScale, Title, Tooltip, TooltipItem } from "chart.js"
import { Bar } from "react-chartjs-2"
import { getFirstDayOfWeek, getLastDayOfWeek, msToPlanningTime, planningTimeToMs, serverDateFormat } from "utils/datetimeUtils"
import MFCard from "components/commun/ui/MFCard"
import Loader from "components/commun/ui/Loader"
import { useCallback, useState } from "react"
import "chartjs-adapter-date-fns"
import { fr } from "date-fns/locale"
import pattern from "patternomaly"
import { createPlannedBlock, createPresenceBlock } from "utils/planningUtils"
import usePlanningDetailsQuery from "hooks/queries/planning/usePlanningDetailsQuery"
import WeekPicker from "./WeekPicker"
import useFilters from "hooks/useFilters"
import MainFilter from "components/commun/MainFilter"
import MembrePopup from "components/personnels/components/MembrePopup"

ChartJS.register(Title, CategoryScale, LinearScale, TimeScale, BarElement, Tooltip, Legend)

const MIN = "07:00:00"
const MAX = "22:00:00"

const options = {
  adapters: {
    date: {
      locale: fr
    }
  },
  bounds: "data",
  animation: {
    duration: 0
  },
  plugins: {
    tooltip: {
      enabled: true,
      backgroundColor: "#fff",
      titleColor: "#000",
      bodyColor: "#333",
      padding: 10,
      borderColor: "#ddd",
      borderWidth: 2,
      callbacks: {
        title: (context: TooltipItem<ChartType>[]) => {
          // Display the username in the tooltip title
          const element = context[0]
          return element.dataset.stack
        },
        label: (context: TooltipItem<ChartType>) => {
          // Format tooltip time values
          const values = Array.isArray(context.raw) ? context.raw as number[] : [0, 0]
          const time1 = msToPlanningTime(values[0])
          const time2 = msToPlanningTime(values[1])

          const shiftName = context.dataset?.label

          if (time1 === "00:00:00" && time2 === "00:00:00") {
            return ""
          }

          return `${shiftName}:  ${time1} - ${time2}`
        }
      }
    },
    legend: {
      display: false,
      align: "start" as "start" | "end" | "center" | undefined
    },
  },
  interaction: {
    mode: "point" as InteractionMode,
  },
  scales: {
    x: {
      stacked: true,
      min: 0,
      max: 6,
    },
    y: {
      stacked: false,
      reverse: true,
      beginAtZero: false,
      type: "time" as any,
      min: planningTimeToMs(MIN),
      max: planningTimeToMs(MAX),
      time: {
        unit: "hour" as any,
        bounds: "data",
        displayFormats: {
          hour: "HH:mm"
        },
      },
    }
  },
  categoryPercentage: 0.8,
  barThickness: "flex" as (number | "flex"),
  inflateAmount: 0,
  borderRadius: 3,
  borderWidth: 0,
  borderSkipped: false,
  indexAxis: "x" as "y" | "x" | undefined,
  responsive: true,
  maintainAspectRatio: false,
}

export default function PlanningChart() {

  const [planningStart, setPlanningStart] = useState<Date | null>(getFirstDayOfWeek())
  const [planningEnd, setPlanningEnd] = useState<Date | null>(getLastDayOfWeek())

  const { magasinFilter } = useFilters()

  const planningDetailsQuery = usePlanningDetailsQuery({
    key: {
      magasin: Number(magasinFilter.value.id) || "",
      date_debut: serverDateFormat(planningStart),
      date_fin: serverDateFormat(planningEnd),
    }
  })

  // transform vertical wheel event to horizontal scroll on the chart
  function handleMouseWheelOnChart(e: React.WheelEvent<HTMLDivElement>) {
    e.currentTarget.scrollLeft += e.deltaY
  }

  const generateDataset = useCallback(() => {
    const dataset: any[] = []

    planningDetailsQuery.data?.forEach(planning => {

      // Shift 1
      dataset.push(createPlannedBlock({
        stack: planning.membre.first_name + " " + planning.membre.last_name,
        label: "Shift 1",
        backgroundColor: `${planning.couleur_planing}66`,
        data: planning.planing_jours.map(planningDay => {
          if (!planningDay.si_jour_repos && planningDay.shift_matin_heure_debut && planningDay.shift_matin_heure_fin) {
            return [
              planningTimeToMs(planningDay.shift_matin_heure_debut),
              planningTimeToMs(planningDay.shift_matin_heure_fin)
            ]
          } else {
            return [0, 0]
          }
        }),
      }))

      // Shift 2
      dataset.push(createPlannedBlock({
        stack: planning.membre.first_name + " " + planning.membre.last_name,
        label: "Shift 2",
        backgroundColor: `${planning.couleur_planing}66`,
        data: planning.planing_jours.map(planningDay => {
          if (!planningDay.si_jour_repos && planningDay.shift_apresmidi_heure_debut && planningDay.shift_apresmidi_heure_fin) {
            return [
              planningTimeToMs(planningDay.shift_apresmidi_heure_debut),
              planningTimeToMs(planningDay.shift_apresmidi_heure_fin)
            ]
          } else {
            return [0, 0]
          }
        }),
      }))

      // Presence 1
      dataset.push(createPresenceBlock({
        stack: planning.membre.first_name + " " + planning.membre.last_name,
        label: "Presence",
        backgroundColor: `${planning.couleur_planing}bb`,
        data: planning.planing_jours.map(planningDay => {
          if (!planningDay.si_jour_repos && planningDay.pointage_matin_heure_debut && planningDay.pointage_matin_heure_fin) {
            return [
              planningDay.pointage_matin_heure_debut ? planningTimeToMs(planningDay.pointage_matin_heure_debut) : 0,
              planningDay.pointage_matin_heure_fin ? planningTimeToMs(planningDay.pointage_matin_heure_fin) : 0
            ]
          } else {
            return [0, 0]
          }
        }),
      }))

      // Presence 2
      dataset.push(createPresenceBlock({
        stack: planning.membre.first_name + " " + planning.membre.last_name,
        label: "Presence",
        backgroundColor: `${planning.couleur_planing}bb`,
        data: planning.planing_jours.map(planningDay => {
          if (!planningDay.si_jour_repos && planningDay.pointage_apresmidi_heure_debut && planningDay.pointage_apresmidi_heure_fin) {
            return [
              planningDay.pointage_apresmidi_heure_debut ? planningTimeToMs(planningDay.pointage_apresmidi_heure_debut) : 0,
              planningDay.pointage_apresmidi_heure_fin ? planningTimeToMs(planningDay.pointage_apresmidi_heure_fin) : 0
            ]
          } else {
            return [0, 0]
          }
        }),
      }))

      // Repos
      dataset.push(createPlannedBlock({
        stack: planning.membre.first_name + " " + planning.membre.last_name,
        label: "Repos",
        backgroundColor: pattern.draw("weave", `${planning.couleur_planing}bb`),
        data: planning.planing_jours.map(planningDay => {
          if (planningDay.si_jour_repos && planningDay.type_repos === "repos") {
            return [
              planningTimeToMs(MIN),
              planningTimeToMs(MAX)
            ]
          } else {
            return [0, 0]
          }
        }),
      }))

      // Conge
      dataset.push(createPlannedBlock({
        stack: planning.membre.first_name + " " + planning.membre.last_name,
        label: "Congé",
        backgroundColor: pattern.draw("plus", `${planning.couleur_planing}bb`),
        data: planning.planing_jours.map(planningDay => {
          if (planningDay.si_jour_repos && planningDay.type_repos === "conge") {
            return [
              planningTimeToMs(MIN),
              planningTimeToMs(MAX)
            ]
          } else {
            return [0, 0]
          }
        }),
      }))

      // Maladie
      dataset.push(createPlannedBlock({
        stack: planning.membre.first_name + " " + planning.membre.last_name,
        label: "Maladie",
        backgroundColor: pattern.draw("ring", `${planning.couleur_planing}bb`),
        data: planning.planing_jours.map(planningDay => {
          if (planningDay.si_jour_repos && planningDay.type_repos === "maladie") {
            return [
              planningTimeToMs(MIN),
              planningTimeToMs(MAX)
            ]
          } else {
            return [0, 0]
          }
        }),
      }))

      // Absence
      dataset.push(createPlannedBlock({
        stack: planning.membre.first_name + " " + planning.membre.last_name,
        label: "Absence",
        backgroundColor: pattern.draw("cross", `${planning.couleur_planing}bb`),
        data: planning.planing_jours.map(planningDay => {
          if (planningDay.si_jour_repos && planningDay.type_repos === "absence") {
            return [
              planningTimeToMs(MIN),
              planningTimeToMs(MAX)
            ]
          } else {
            return [0, 0]
          }
        }),
      }))

    })
    return dataset
  }, [planningDetailsQuery.data])

  const data = {
    labels: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
    datasets: generateDataset()
  }

  function Chart() {
    if (planningDetailsQuery.isLoading) {
      return <Loader />
    }

    if (planningDetailsQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
          sx={{ my: 4 }}
        >
            Une erreur est survenue
        </Alert>
      )
    }

    if (planningDetailsQuery.data.length === 0 && magasinFilter.options.length > 1) {
      return (
        <Alert
          severity="info"
          variant="standard"
          sx={{ my: 4 }}
        >
          Veuillez choisir un magasin
        </Alert>
      )
    }

    if (planningDetailsQuery.data.length === 0) {
      return (
        <Alert
          severity="warning"
          variant="standard"
          sx={{ my: 4 }}
        >
          Le planning est vide
        </Alert>
      )
    }

    return (
      <>
        <Stack padding={4} direction="row" flexWrap="wrap" spacing={4}>
          {planningDetailsQuery.data.map(planning => (
            <div className="color-key" key={planning.id}>
              <span className="color-box" style={{ backgroundColor: planning.couleur_planing }}></span>
              <MembrePopup membreId={planning.membre.id}>
                {planning.membre.first_name + " " + planning.membre.last_name}
              </MembrePopup>
            </div>
          ))}
        </Stack>
        <div style={{ width: "100%", overflowX: "scroll" }} onWheel={handleMouseWheelOnChart}>
          <div style={{ minWidth: "100%", width: planningDetailsQuery.data?.length * 320, height: 500 }}>
            <Bar options={options} data={data} />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="planning">
      <MFCard variant="outlined" title="Planning">
        <Box padding={4} sx={{ position: "relative" }}>

          <Stack direction="row" justifyContent="flex-end" spacing={4}>
            <MainFilter
              magasinFilter={magasinFilter}
            />

            <WeekPicker
              planningStart={planningStart}
              setPlanningStart={setPlanningStart}
              planningEnd={planningEnd}
              setPlanningEnd={setPlanningEnd}
            />
          </Stack>

          {Chart()}
        </Box>
      </MFCard>
    </div>
  )
}
