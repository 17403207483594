import { Navigate, Outlet, useLocation } from "react-router-dom"
import { URL_DASHBOARD } from "constants/urls"

type Props = {
  isAuth: boolean
}

export default function PublicRoutes({ isAuth }: Props) {
  const location = useLocation()

  if (isAuth) {
    return <Navigate to={URL_DASHBOARD} state={{ from: location }} replace />
  }

  return <Outlet />
}
