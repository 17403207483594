import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_NOTIFICATIONS } from "constants/endpoints"
import { Pagination } from "constants/types"
import { Cookies } from "react-cookie"

export type NotificationsRequest = {
  p?: number
}

export type NotificationsResponse = {
  id: number,
  message: string,
  type_message: string,
  statut_envoi_android: string,
  description_android: string,
  nbr_android: number,
  nbr_android_recu: number,
  statut_envoi_ios: string,
  description_ios: string,
  nbr_ios: number,
  nbr_ios_recu: number,
  statut_notif: string,
  created_at: Date
}

export type AchatsError = {
  detail: "Authentication credentials were not provided." | "Invalid page." | undefined
}

export default async function notifications(params: NotificationsRequest = {}) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_NOTIFICATIONS,
    params: {
      si_paginated: "True",
      ...params
    }
  })

  return response.data as Pagination<NotificationsResponse>
}
