import { Alert, Stack } from "@mui/material"
import Loader from "components/commun/ui/Loader"
import MFCard from "components/commun/ui/MFCard"
import MembrePopup from "components/personnels/components/MembrePopup"
import useAbsencesQuery from "hooks/queries/assiduite/useAbsencesQuery"
import { serverDateFormat, standardDateFormat } from "utils/datetimeUtils"
import UserStatusCard from "./UserStatusCard"

export default function AbsencesList() {

  const absencesQuery = useAbsencesQuery({
    key: {
      date_start: serverDateFormat(new Date()),
      date_end: serverDateFormat(new Date()),
    }
  })

  if (absencesQuery.isLoading) {
    return (
      <MFCard variant="outlined" title="Les absences">
        <Loader />
      </MFCard>
    )
  }

  if (absencesQuery.isError) {
    return (
      <MFCard variant="outlined" title="Les absences">
        <Alert
          severity="error"
          variant="standard"
          sx={{ m: 4 }}
        >
          Une erreur est survenue
        </Alert>
      </MFCard>
    )
  }

  if (absencesQuery.data.count === 0) {
    return (
      <MFCard variant="outlined" title="Les absences">
        <Alert
          severity="success"
          variant="standard"
          sx={{ m: 4 }}
        >
          Aucune absence aujourd'hui
        </Alert>
      </MFCard>
    )
  }

  return (
    <MFCard variant="outlined" title="Les absences">
      <Stack textAlign="center" spacing={4} sx={{ p: 4 }}>
        {absencesQuery.data.results.slice(0, 3).map(absence => (
          <MembrePopup key={absence.id} membreId={absence.membre.id} fullWidth>
            <UserStatusCard
              membre={{
                first_name: absence.membre.first_name,
                last_name: absence.membre.last_name,
                fonction: absence.membre.poste_nom,
                photo: absence.membre.photo,
              }}
              status="absence"
              startDate={absence.date_debut ? standardDateFormat(absence.date_debut) : "-"}
              endDate={absence.date_fin ? standardDateFormat(absence.date_fin) : "-"}
            />
          </MembrePopup>
        ))}
      </Stack>
    </MFCard>
  )
}
