import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_ACHATS } from "constants/endpoints"
import { MembreSubType, Pagination } from "constants/types"
import { Cookies } from "react-cookie"

export type AchatsRequest = {
  si_paginated?: "True" | "False"
  p?: number
  magasin?: number | ""
  personel?: number | ""
  date_start?: string | ""
  date_end?: string | ""
}

export type AchatsResponse = {
  id: number
  numero_ticket: string
  chiffre_affaire: number
  carte_fidelity: number
  point_vente: string
  marque: string
  montant_total: number
  date_achat: Date
  created_at: Date
  membre: MembreSubType
}

export type AchatsError = {
  detail: "Authentication credentials were not provided." | "Invalid page." | undefined
}

export default async function achats(params: AchatsRequest = {}) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_ACHATS,
    params: {
      si_paginated: "True",
      ...params
    }
  })

  return response.data as Pagination<AchatsResponse>
}
