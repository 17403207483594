import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_CONGES } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type UpdateCongeRequest = {
  id: number
  statut: "en_attente" | "valide" | "rejete"
}

export type UpdateCongeResponse = {
  id: number
  statut: "en_attente" | "valide" | "rejete"
}

export type UpdateCongeError = {
  detail: "Authentication credentials were not provided." | undefined
}

export default async function updateConge(params: UpdateCongeRequest) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "PATCH",
    url: API_CONGES + params.id + "/",
    data: params
  })

  return response.data as UpdateCongeResponse
}
