// Strips empty params from a query object and returns a new object
export function stripEmptyKeys<Type>(params: Type | undefined) {
  const paramsClone = { ...params }

  Object.keys(paramsClone).forEach(key => {
    if (
      paramsClone[key as keyof typeof params] === ""
      || paramsClone[key as keyof typeof params] === undefined
      || paramsClone[key as keyof typeof params] === null
    ) {
      delete paramsClone[key as keyof typeof params]
    }
  })

  return paramsClone as Type
}
