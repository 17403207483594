import "static/styles/dashboard.scss"
import { Grid, Stack } from "@mui/material"
import Actions from "./components/Actions"
import AbsencesList from "./components/AbsencesList"
import RetardsList from "./components/RetardsList"
import PlanningChart from "./components/PlanningChart"
import { useDocumentTitle } from "usehooks-ts"

export default function Dashboard() {

  useDocumentTitle("Mafranchise | Dashboard")

  return (
    <div className="dashboard">
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <Stack spacing={4}>
            <Actions />
            <PlanningChart />
          </Stack>
        </Grid>

        <Grid item xs={4}>
          <Stack spacing={4}>
            <AbsencesList />
            <RetardsList />
          </Stack>
        </Grid>
      </Grid>
    </div>
  )
}
