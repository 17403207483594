import { useQuery } from "@tanstack/react-query"
import membreById, { MembreByIdRequest, MembreByIdError } from "api/membres/membreById"
import { AxiosError } from "axios"
import { KEY_MEMBRES } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { stripEmptyKeys } from "utils/queryUtils"

export default function useMembreByIdQuery(args: CustomQueryArgs<MembreByIdRequest> = {}) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_MEMBRES, strippedKey] : [KEY_MEMBRES],
    queryFn: () => membreById(strippedKey),
    onError: (err: AxiosError<MembreByIdError>) => err,
    ...args.options
  })
}
