import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_DOCUMENTS } from "constants/endpoints"
import { MembreSubType, Pagination } from "constants/types"
import { Cookies } from "react-cookie"

export type DocumentType = "attestaion_salaire" | "attestaion_travail" | "certificat_travail" | ""
export type DocumentStatus = "en_attente" | "traite"

export type DocumentsRequest = {
  p?: number
  statut?: DocumentStatus
  type_document?: DocumentType
  magasin?: number | ""
  personel?: number | ""
  date_start?: string | ""
  date_end?: string | ""
}

export type DocumentsResponse = {
  id: number
  date_demande: Date
  type_document: DocumentType
  statut: DocumentStatus
  commentaire: string
  pj: string
  created_at: Date
  membre: MembreSubType
}

export type DocumentsError = {
  detail: "Authentication credentials were not provided." | "Invalid page." | undefined
}

export default async function conges(params: DocumentsRequest = {}) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_DOCUMENTS,
    params: {
      si_paginated: "True",
      ...params
    }
  })

  return response.data as Pagination<DocumentsResponse>
}
