import { Alert, Autocomplete, Avatar, Box, Button, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { URL_NOTIFICATIONS } from "constants/urls"
import useTokensQuery from "hooks/queries/notifications/useTokensQuery"
import { useState } from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { ArrowLeft, NotificationAdd, Work } from "@mui/icons-material"
import useSendNotificationMutation from "hooks/queries/notifications/useSendNotificationMutation"
import useFilters from "hooks/useFilters"
import MainFilter from "components/commun/MainFilter"
import Loader from "components/commun/ui/Loader"
import MFCard from "components/commun/ui/MFCard"
import MembrePopup from "components/personnels/components/MembrePopup"

const notificationTypeOptions = [
  { label: "Absence", id: "@@absence@@" },
  { label: "Congé", id: "@@conge@@" },
  { label: "Document", id: "@@document@@" },
  { label: "Autre", id: "@@autre@@" },
]

export default function SendNotifications() {

  const [selectedTokens, setSelectedTokens] = useState<string[]>([])
  const [notificationType, setNotificationType] = useState({ label: "", id: "" })
  const [notificationMessage, setNotificationMessage] = useState("")

  const {
    magasinFilter,
    platformFilter,
    posteFilter,
    marqueFilter
  } = useFilters()

  const navigate = useNavigate()

  const tokensQuery = useTokensQuery({
    key: {
      platform: platformFilter.value.id || "",
      magasin: Number(magasinFilter.value.id) || "",
      poste: posteFilter.value.id || "",
      marque: marqueFilter.value.id || "",
    }
  })
  const sendNotificationMutation = useSendNotificationMutation()

  function handleCheckAllTokens(checked: boolean) {
    if (checked) {
      setSelectedTokens(tokensQuery.data?.map(token => token.id.toString()) || [])
    } else {
      setSelectedTokens([])
    }
  }

  function handleTokenCheck(id: string, checked: boolean) {
    if (checked) {
      setSelectedTokens([...selectedTokens, id])
    } else {
      setSelectedTokens(selectedTokens.filter(token => token !== id))
    }
  }

  function handleSendNotification() {
    if (notificationType.id === "") {
      toast.error("Veuillez choisir un type de notification")
      return
    }

    if (notificationMessage.trim() === "") {
      toast.error("Veuillez entrer le texte du notification")
      return
    }

    if (selectedTokens.length === 0) {
      toast.error("Veuillez choisir au moins un appareil")
      return
    }

    const notification = notificationType.id + notificationMessage
    sendNotificationMutation.mutate({
      tokens: selectedTokens.map(token => parseInt(token)),
      categorie: notificationType.id,
      message: notification,
    }, {
      onSuccess: () => navigate(URL_NOTIFICATIONS)
    })
  }

  function TokensList() {
    if (tokensQuery.isLoading) {
      return <Loader />
    }

    if (tokensQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
        >
          Une erreur est survenue
        </Alert>
      )
    }

    if (tokensQuery.data.length === 0) {
      return (
        <Alert
          severity="warning"
          variant="standard"
          sx={{ mt: 8 }}
        >
          La liste des appareils est vide
        </Alert>
      )
    }

    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="info"
                  checked={tokensQuery.data?.every(token => selectedTokens.includes(token.id.toString())) || false}
                  onChange={(_event, checked) => handleCheckAllTokens(checked)}
                />
              </TableCell>
              <TableCell>Membre</TableCell>
              <TableCell>Poste</TableCell>
              <TableCell>Magasin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tokensQuery.data?.map(token => (
              <TableRow key={token.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="info"
                    checked={selectedTokens.includes(token.id.toString())}
                    onChange={(_event, checked) => handleTokenCheck(token.id.toString(), checked)}
                  />
                </TableCell>
                <TableCell>
                  <MembrePopup membreId={token.id}>
                    <Stack direction="row" alignItems="center">
                      <Avatar src={token.photo || undefined} sx={{ mr: 2 }} />
                      <Stack direction="column" alignItems="flex-start">
                        <Typography fontSize="1rem">{token.first_name + " " + token.last_name}</Typography>
                        <Stack direction="row" alignItems="center">
                          <Work htmlColor="#666666" sx={{ fontSize: "0.9rem", mr: 1 }} />
                          <Typography fontSize="0.8rem" color="#666666">{token.poste_nom || "-"}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </MembrePopup>
                </TableCell>
                <TableCell>{token.poste_nom}</TableCell>
                <TableCell>{token.magasin_nom}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" spacing={4} mb={4}>
        <Button
          variant="contained"
          color="info"
          startIcon={<ArrowLeft />}
          onClick={() => navigate(URL_NOTIFICATIONS)}
        >
          Retour a la liste des notifications
        </Button>

        {selectedTokens.length > 0 && (
          <Typography variant="body1">
            {selectedTokens.length} membre(s) sélectionné(s)
          </Typography>
        )}

        <Button
          variant="contained"
          color="success"
          startIcon={<NotificationAdd />}
          onClick={handleSendNotification}
        >
          Envoyer la notification
        </Button>
      </Stack>

      <MFCard variant="outlined">
        <Stack spacing={4} direction="column" m={6}>
          <Stack spacing={2} direction="row">
            <MainFilter
              magasinFilter={magasinFilter}
              posteFilter={posteFilter}
              marqueFilter={marqueFilter}
            />
          </Stack>

          <Stack spacing={2} direction="row">
            <Autocomplete
              id="type"
              sx={{ width: 300 }}
              options={notificationTypeOptions}
              onChange={(_event, value) => setNotificationType(value || { label: "", id: "" })}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={params => (
                <TextField {...params} label="Type de notification" />
              )}
            />

            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              type="text"
              label="Message"
              value={notificationMessage}
              onChange={(e) => setNotificationMessage((e.target as HTMLInputElement).value)}
            />
          </Stack>
        </Stack>
      </MFCard>

      {TokensList()}
    </Box>
  )
}
