import { Download, Work } from "@mui/icons-material"
import { Alert, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Avatar, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import achatsExport from "api/achats/achatsExport"
import MainFilter from "components/commun/MainFilter"
import MainPagination from "components/commun/MainPagination"
import Loader from "components/commun/ui/Loader"
import MFCard from "components/commun/ui/MFCard"
import MembrePopup from "components/personnels/components/MembrePopup"
import useAchatsQuery from "hooks/queries/achats/useAchatsQuery"
import useFilters from "hooks/useFilters"
import usePagination from "hooks/usePagination"
import { useState } from "react"
import BarLoader from "react-spinners/BarLoader"
import { serverDateFormat, standardDateFormat } from "utils/datetimeUtils"
import { utils, writeFileXLSX } from "xlsx"

export default function Achats() {

  const pagination = usePagination(1)
  const {
    magasinFilter,
    membreFilter,
    dateStartFilter,
    dateEndFilter,
  } = useFilters(() => pagination.changePage(1))

  const [isExporting, setIsExporting] = useState(false)

  const achatsQuery = useAchatsQuery({
    key: {
      p: pagination.page,
      magasin: Number(magasinFilter.value.id) || "",
      personel: Number(membreFilter.value.id) || "",
      date_start: serverDateFormat(dateStartFilter.value) || "",
      date_end: serverDateFormat(dateEndFilter.value) || "",
    }
  })

  async function exportToExcel() {
    // We call the api function directly here because we don't need the cache and we don't want to use the pagination
    setIsExporting(true)
    const achatsExportData = await achatsExport({
      magasin: Number(magasinFilter.value.id) || "",
      personel: Number(membreFilter.value.id) || "",
      date_start: serverDateFormat(dateStartFilter.value) || "",
      date_end: serverDateFormat(dateEndFilter.value) || "",
    })

    // Flatten membre object
    const exportObjects: any = []

    achatsExportData.forEach((achat, index) => {
      exportObjects.push({
        ...achat,
        nom: achat.membre.first_name,
        prenom: achat.membre.last_name,
        poste: achat.membre.poste_nom,
        ville: achat.membre.nom_ville,
        magasin: achat.membre.magasin_nom,
      })

      delete exportObjects[index].membre
    })

    const ws = utils.json_to_sheet(exportObjects)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, "Data")
    writeFileXLSX(wb, `Achats_${serverDateFormat(new Date())}.xlsx`)

    setIsExporting(false)
  }

  function AchatsList() {
    if (achatsQuery.isLoading) {
      return <Loader />
    }

    if (achatsQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
        >
          Une erreur est survenue
        </Alert>
      )
    }

    if (achatsQuery.data.count === 0) {
      return (
        <Alert
          severity="warning"
          variant="standard"
          sx={{ mt: 8 }}
        >
          La liste des achats est vide
        </Alert>
      )
    }

    return (
      <Box textAlign="center">
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Membre</TableCell>
                <TableCell>Marque</TableCell>
                <TableCell>Magasin</TableCell>
                <TableCell>Montant total</TableCell>
                <TableCell>Date achat</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {achatsQuery.data.results.map((achat) => (
                <TableRow key={achat.id}>
                  <TableCell>
                    <MembrePopup membreId={achat.membre.id}>
                      <Stack direction="row" alignItems="center">
                        <Avatar src={achat.membre.photo} sx={{ mr: 2 }} />
                        <Stack direction="column" alignItems="flex-start">
                          <Typography fontSize="1rem">{achat.membre.first_name + " " + achat.membre.last_name}</Typography>
                          <Stack direction="row" alignItems="center">
                            <Work htmlColor="#666666" sx={{ fontSize: "0.9rem", mr: 1 }} />
                            <Typography fontSize="0.8rem" color="#666666">{achat.membre.poste_nom || "-"}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </MembrePopup>
                  </TableCell>
                  <TableCell>{achat.marque}</TableCell>
                  <TableCell>{achat.point_vente}</TableCell>
                  <TableCell>{achat.montant_total}</TableCell>
                  <TableCell>{standardDateFormat(achat.date_achat)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <MainPagination
          page={pagination.page}
          numberOfPages={pagination.countPages(achatsQuery.data.count, achatsQuery.data.count_per_page)}
          changePage={pagination.changePage}
        />
      </Box>
    )
  }

  return (
    <Box>
      <Stack direction="row" justifyContent="end" mb={6}>
        <Button
          variant="contained"
          title="Exporter la liste des achats"
          startIcon={!isExporting && <Download />}
          disabled={isExporting}
          onClick={exportToExcel}
        >
          {isExporting && (
            <BarLoader
              color="#660917"
              height={4}
              width={50}
              speedMultiplier={0.9}
              cssOverride={{ marginRight: 8 }}
            />
          )}
          Exporter
        </Button>
      </Stack>

      <MFCard variant="outlined">
        <Stack spacing={2} direction="row" m={6}>
          <MainFilter
            magasinFilter={magasinFilter}
            membreFilter={membreFilter}
            dateStartFilter={dateStartFilter}
            dateEndFilter={dateEndFilter}
          />
        </Stack>
      </MFCard>

      {AchatsList()}
    </Box>
  )
}
