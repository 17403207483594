import { ArrowLeft, EventAvailable } from "@mui/icons-material"
import { Autocomplete, Box, Button, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers"
import { URL_PLANNING } from "constants/urls"
import usePlanStore, { PlanDay } from "features/planning"
import useFilters, { emptyOption } from "hooks/useFilters"
import { useNavigate } from "react-router-dom"
import { getNameOfDay, serverDateFormat, standardDateFormat, serverTimeFormat } from "utils/datetimeUtils"
import { useEffect } from "react"
import useCreatePlanningMutation from "hooks/queries/planning/useCreatePlanningMutation"
import { toast } from "react-hot-toast"
import MembreCongeInfo from "./components/MembreCongeInfo"
import useUpdatePlanningMutation from "hooks/queries/planning/useUpdatePlanningMutation"
import useNowQuery from "hooks/queries/variables/useNowQuery"
import { isSameDay, isValid } from "date-fns"
import isAfter from "date-fns/isAfter"

const dayOffTypeOptions = [
  { label: "Congé", id: "conge" },
  { label: "Repos", id: "repos" },
  { label: "Maladie", id: "maladie" },
]

export default function CreatePlanning() {

  const navigate = useNavigate()

  const {
    planningId,
    membreId,
    planDays,
    setShift1Start,
    setShift1End,
    setShift2Start,
    setShift2End,
    setDayOff,
    setDayOffType,
    initDays,
    emptyDay,
    reset,
  } = usePlanStore()

  const now = useNowQuery()

  // Check if the date of the system is the same as the server date to avoid creating a planning with a wrong date
  // If it's not the same, we redirect the user to the planning page
  useEffect(() => {
    if (now.data) {
      if (!isSameDay(now.data, new Date())) {
        toast.error("Veuillez mettre la date de votre system a jour pour pouvoir créer un planning.", { id: "dateSync" })
        reset()
        return navigate(URL_PLANNING)
      }
    }
  })

  const { membreFilter } = useFilters()

  useEffect(() => {
    if (planningId === 0) {
      initDays()
    }

    return () => {
      reset()
    }
  }, [])

  const createPlanningMutation = useCreatePlanningMutation()
  const updatePlanningMutation = useUpdatePlanningMutation()

  /**
   * Planning validation
   * day off must have a day off type
   * at least one shift must be valid
   * a shift needs to have a start and an end
   * shift start must be before shift end
   * shift1 must be before shift2 if they both exist
   */
  function publishPlanning() {
    const unspecifiedMembre = !membreFilter.value.id && !membreId
    const unspecifiedDayOff = planDays.some(day => day.isDayOff && !day.dayOffType)
    const validShift1 = (day: PlanDay) => isValid(day.shift1Start) && isValid(day.shift1End)
    const validShift2 = (day: PlanDay) => isValid(day.shift2Start) && isValid(day.shift2End)
    const oneValidShift = (day: PlanDay) => validShift1(day) || validShift2( day)

    if (unspecifiedMembre) {
      return toast.error("Veuillez choisir un membre")
    }

    if (unspecifiedDayOff) {
      return toast.error("Veuillez specifier le type des jours off")
    }

    if (planDays.some(day => !oneValidShift(day) && !day.isDayOff)) {
      return toast.error("Veuillez remplir tous les jours")
    }

    const planningDays = planDays.map(planDay => {
      if (planDay.isDayOff === true) {
        return {
          date_jour: serverDateFormat(planDay.date),
          jour: getNameOfDay(new Date(planDay.date || "")).toLowerCase(),
          // shift_matin_heure_debut: null,
          // shift_matin_heure_fin: null,
          // shift_apresmidi_heure_debut: null,
          // shift_apresmidi_heure_fin: null,
          si_jour_repos: planDay.isDayOff,
          type_repos: planDay.dayOffType,
        }
      } else {
        return {
          date_jour: serverDateFormat(planDay.date),
          jour: getNameOfDay(new Date(planDay.date || "")).toLowerCase(),
          shift_matin_heure_debut: isValid(planDay.shift1Start) && isValid(planDay.shift1End) ? serverTimeFormat(planDay.shift1Start) : null,
          shift_matin_heure_fin: isValid(planDay.shift1Start) && isValid(planDay.shift1End) ? serverTimeFormat(planDay.shift1End) : null,
          shift_apresmidi_heure_debut: isValid(planDay.shift2Start) && isValid(planDay.shift2End) ? serverTimeFormat(planDay.shift2Start) : null,
          shift_apresmidi_heure_fin: isValid(planDay.shift2Start) && isValid(planDay.shift2End) ? serverTimeFormat(planDay.shift2End) : null,
          si_jour_repos: planDay.isDayOff,
          type_repos: null,
        }
      }
    })

    if (planningId) {
      updatePlanningMutation.mutate({
        id: planningId,
        planing_jours: planningDays
      }, {
        onSuccess: () => {
          reset()
          navigate(URL_PLANNING)
        }
      })
    }
    else {
      createPlanningMutation.mutate({
        employe: Number(membreFilter.value.id),
        date_debut: serverDateFormat(planDays[0].date),
        date_fin: serverDateFormat(planDays[6].date),
        planing_jours: planningDays
      }, {
        onSuccess: () => {
          reset()
          navigate(URL_PLANNING)
        }
      })
    }
  }

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4} mb={4}>
        <Button
          variant="contained"
          color="info"
          startIcon={<ArrowLeft />}
          onClick={() => navigate(URL_PLANNING)}
        >
          Retour a la liste des plannings
        </Button>

        <Stack direction="row" alignItems="center" spacing={2}>
          <MembreCongeInfo
            membreId={membreId !== 0 ? membreId : Number(membreFilter.value.id)}
            dateStart={planDays[0]?.date}
            dateEnd={planDays[6]?.date}
          />

          {membreId === 0 ? (
            <Autocomplete
              id="membre"
              sx={{ width: 300 }}
              options={membreFilter.options || emptyOption}
              onChange={(event, value) => membreFilter.setValue(value || emptyOption)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={params => <TextField {...params} label="Membre" />}
            />
          ) : (
            <Typography variant="subtitle1">{membreFilter.options.find(option => option.id === membreId.toString())?.label}</Typography>
          )}
        </Stack>

        <Button
          variant="contained"
          color="success"
          startIcon={<EventAvailable />}
          onClick={publishPlanning}
        >
          {planningId === 0 ? "Publier le planning" : "Modifier le planning"}
        </Button>
      </Stack>

      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Jour</TableCell>
              <TableCell align="center">Shift 1</TableCell>
              <TableCell align="center">Shift 2</TableCell>
              <TableCell align="center">Jour off</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planDays.map((planDay, index) => {
              const isModifiable = planDay.date && isAfter(new Date(planDay.date), new Date())
              return (
                <TableRow key={index} sx={{ opacity: isModifiable ? 1 : .5 }}>
                  <TableCell sx={{ minWidth: 120 }}>
                    <Typography>{planDay.date && getNameOfDay(new Date(planDay.date))}</Typography>
                    <Typography>{planDay.date && standardDateFormat(planDay.date)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={4}>
                      <TimePicker
                        label="Heure de debut"
                        value={planDay.shift1Start}
                        disabled={planDay.isDayOff || !isModifiable}
                        onChange={value => setShift1Start(index, value)}
                        renderInput={params => <TextField fullWidth {...params} error={false} />}
                      />
                      <TimePicker
                        label="Heure de fin"
                        value={planDay.shift1End}
                        disabled={planDay.isDayOff || !isModifiable}
                        onChange={value => setShift1End(index, value)}
                        renderInput={params => <TextField fullWidth {...params} error={false} />}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={4}>
                      <TimePicker
                        label="Heure de debut"
                        value={planDay.shift2Start}
                        disabled={planDay.isDayOff || !isModifiable}
                        onChange={value => setShift2Start(index, value)}
                        renderInput={params => <TextField fullWidth {...params} error={false} />}
                      />
                      <TimePicker
                        label="Heure de fin"
                        value={planDay.shift2End}
                        disabled={planDay.isDayOff || !isModifiable}
                        onChange={value => setShift2End(index, value)}
                        renderInput={params => <TextField fullWidth {...params} error={false} />}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="info"
                      checked={planDay.isDayOff}
                      disabled={!isModifiable}
                      onChange={() => {
                        setDayOff(index, !planDay.isDayOff)
                        emptyDay(index)
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      id="type"
                      sx={{ width: 170 }}
                      disabled={!planDay.isDayOff || !isModifiable}
                      options={dayOffTypeOptions}
                      value={dayOffTypeOptions.find(option => option.id === planDay.dayOffType) || null}
                      onChange={(event, value) => setDayOffType(index, value?.id || null)}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={params => (
                        <TextField {...params} label="Type de jour off" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => emptyDay(index)} disabled={!isModifiable} color="error">Vider</Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
