import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_POSTS } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type PostsResponse = {
  id: number
  poste: string
}

export type PostsError = {
  detail: "Authentication credentials were not provided." | undefined
}

export default async function postes() {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_POSTS,
  })

  return response.data as PostsResponse[]
}
