import { Work } from "@mui/icons-material"
import ArrowRight from "@mui/icons-material/ArrowRightAlt"
import { Alert, Avatar, Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import MainFilter from "components/commun/MainFilter"
import MainPagination from "components/commun/MainPagination"
import Loader from "components/commun/ui/Loader"
import MFCard from "components/commun/ui/MFCard"
import MembrePopup from "components/personnels/components/MembrePopup"
import useAbsencesQuery from "hooks/queries/assiduite/useAbsencesQuery"
import useFilters from "hooks/useFilters"
import usePagination from "hooks/usePagination"
import { serverDateFormat, standardDateFormat, standardTimeFormat } from "utils/datetimeUtils"

export default function AbsencesDemandes() {

  const pagination = usePagination(1)
  const {
    magasinFilter,
    membreFilter,
    dateStartFilter,
    dateEndFilter,
  } = useFilters(() => pagination.changePage(1))

  const absencesQuery = useAbsencesQuery({
    key: {
      p: pagination.page,
      magasin: Number(magasinFilter.value.id) || "",
      personel: Number(membreFilter.value.id) || "",
      date_start: serverDateFormat(dateStartFilter.value) || "",
      date_end: serverDateFormat(dateEndFilter.value) || "",
      type: "pointage",
    }
  })

  function AbsencesList() {
    if (absencesQuery.isLoading) {
      return <Loader />
    }

    if (absencesQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
          sx={{ mt: 8 }}
        >
          Une erreur est survenue
        </Alert>
      )
    }

    if (absencesQuery.data.count === 0) {
      return (
        <Alert
          severity="warning"
          variant="standard"
          sx={{ mt: 8 }}
        >
          La liste des absences est vide
        </Alert>
      )
    }

    return (
      <>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Membre</TableCell>
                <TableCell>Date d'absence</TableCell>
                <TableCell>Periode</TableCell>
                <TableCell>Durée</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {absencesQuery.data.results.map(absence => (
                <TableRow key={absence.id}>
                  <TableCell>
                    <MembrePopup membreId={absence.membre.id}>
                      <Stack direction="row" alignItems="center">
                        <Avatar src={absence.membre.photo} sx={{ mr: 2 }} />
                        <Stack direction="column" alignItems="flex-start">
                          <Typography fontSize="1rem">{absence.membre.first_name + " " + absence.membre.last_name}</Typography>
                          <Stack direction="row" alignItems="center">
                            <Work htmlColor="#666666" sx={{ fontSize: "0.9rem", mr: 1 }} />
                            <Typography fontSize="0.8rem" color="#666666">{absence.membre.poste_nom || "-"}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </MembrePopup>
                  </TableCell>
                  <TableCell>{standardDateFormat(absence.date_demande)} | {standardTimeFormat(absence.date_demande)}</TableCell>
                  <TableCell>
                    <Stack spacing={2} direction="row" alignItems="center">
                      {standardDateFormat(absence.date_debut)}
                      <ArrowRight color="action" sx={{ px: 1 }} />
                      {absence.date_fin ? standardDateFormat(absence.date_fin) : "-"}
                    </Stack>
                  </TableCell>
                  <TableCell>{absence.duree ? `${absence.duree} jour(s)` : "-"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <MainPagination
          page={pagination.page}
          numberOfPages={pagination.countPages(absencesQuery.data.count, absencesQuery.data.count_per_page)}
          changePage={pagination.changePage}
        />
      </>
    )
  }

  return (
    <Box>
      <MFCard variant="outlined">
        <Stack spacing={2} direction="row" m={6}>
          <MainFilter
            magasinFilter={magasinFilter}
            membreFilter={membreFilter}
            dateStartFilter={dateStartFilter}
            dateEndFilter={dateEndFilter}
          />
        </Stack>
      </MFCard>

      {AbsencesList()}
    </Box>
  )
}
