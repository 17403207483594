import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_TOKENS } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type TokensRequest = {
  p?: number
  platform?: string | ""
  magasin?: number | ""
  poste?: string | ""
  marque?: string | ""
}

export type TokensResponse = {
  id: number,
  username: string,
  identifiant: string
  telephone: string
  last_name: string
  first_name: string
  email: string
  adresse: string
  ville: string | null
  ville_nom: string |null
  poste: number
  poste_nom: string
  magasin: number
  magasin_nom: string
  identifiant_point_vente: string
  photo: string | null
  responsable: string
  created_at: string
  updated_at: string
}

export type AchatsError = {
  detail: "Authentication credentials were not provided." | "Invalid page." | undefined
}

export default async function tokens(params: TokensRequest = {}) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_TOKENS,
    params: params
  })

  return response.data as TokensResponse[]
}
