import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_SEND_NOTIFICATION } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type SendNotificationRequest = {
  tokens: number[]
  message: string
  select_all?: "True" | "False"
  categorie?: string
}

export type SendNotificationResponse = {
  id: number
}

export type SendNotificationError = {
  detail: "Authentication credentials were not provided." | undefined
}

export default async function sendNotification(params: SendNotificationRequest) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "POST",
    url: API_SEND_NOTIFICATION,
    data: {
      ...params,
      select_all: params.select_all || "False"
    }
  })

  return response.data as SendNotificationResponse
}
