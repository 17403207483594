import { Avatar, Button, Menu, MenuItem, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useCookies } from "react-cookie"
import MainDialog from "components/commun/MainDialog"
import { LogoutOutlined as LogoutIcon } from "@mui/icons-material"
import { toast } from "react-hot-toast"
import { useBoolean } from "usehooks-ts"
import { COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN } from "constants/cookies"
import useInfoProfileQuery from "hooks/queries/auth/useInfoProfileQuery"

export default function User() {

  const [, , removeCookie] = useCookies([COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN])
  const userInfoQuery = useInfoProfileQuery()
  const queryClient = useQueryClient()

  const logoutDialogHandler = useBoolean(false)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  function logout() {
    removeCookie(COOKIE_ACCESS_TOKEN)
    removeCookie(COOKIE_REFRESH_TOKEN)

    // Cancel all pending queries if any
    queryClient.cancelQueries({
      type: "all"
    })

    // Remove all queries from cache
    queryClient.removeQueries({
      type: "all"
    })

    toast.success("Vous êtes déconnecté")
  }

  return (
    <div className="user">
      <Button color="inherit" onClick={event => setAnchorEl(event.currentTarget)} sx={{ m: 0, p: 2 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            variant="circular"
            alt={userInfoQuery.data?.first_name + " " + userInfoQuery.data?.last_name}
            src={userInfoQuery.data?.photo}
          />
          <Typography color="black">{`${userInfoQuery.data?.first_name} ${userInfoQuery.data?.last_name}`}</Typography>
        </Stack>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{ maxWidth: 350 }}
      >
        <MenuItem onClick={() => {
          setAnchorEl(null)
          logoutDialogHandler.setTrue()
        }}>
          <Stack direction="row" alignItems="center" justifyContent="flex-start">
            <LogoutIcon sx={{ mr: 2, fill: "#000000DE" }} />
            Se déconnecter
          </Stack>
        </MenuItem>
      </Menu>

      <MainDialog
        isOpen={logoutDialogHandler.value}
        closeFn={logoutDialogHandler.setFalse}
        title="Se déconnecter"
        content="Voulez-vous vraiment vous déconnecter ?"
        agree={{ text: "Se déconnecter", fn: logout }}
        disagree={{ text: "Annuler", fn: () => 0 }}
      />
    </div>
  )
}
