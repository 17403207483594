import { Cancel, CheckCircle, Schedule, Work } from "@mui/icons-material"
import ArrowRight from "@mui/icons-material/ArrowRightAlt"
import { Alert, Avatar, Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { CongesResponse } from "api/conges/conges"
import MainDialog from "components/commun/MainDialog"
import MainFilter from "components/commun/MainFilter"
import MainPagination from "components/commun/MainPagination"
import Loader from "components/commun/ui/Loader"
import MFCard from "components/commun/ui/MFCard"
import MembrePopup from "components/personnels/components/MembrePopup"
import { differenceInDays } from "date-fns"
import useCongesQuery from "hooks/queries/conges/useCongesQuery"
import useUpdateCongeMutation from "hooks/queries/conges/useUpdateCongeMutation"
import useFilters from "hooks/useFilters"
import usePagination from "hooks/usePagination"
import { useState } from "react"
import toast from "react-hot-toast"
import { useBoolean } from "usehooks-ts"
import { serverDateFormat, standardDateFormat, standardTimeFormat } from "utils/datetimeUtils"

export default function CongesAttente() {

  const pagination = usePagination(1)
  const {
    magasinFilter,
    membreFilter,
    dateStartFilter,
    dateEndFilter,
  } = useFilters(() => pagination.changePage(1))

  const congesQuery = useCongesQuery({
    key: {
      p: pagination.page,
      statut: "en_attente",
      magasin: Number(magasinFilter.value.id) || "",
      personel: Number(membreFilter.value.id) || "",
      date_start: serverDateFormat(dateStartFilter.value) || "",
      date_end: serverDateFormat(dateEndFilter.value) || "",
    }
  })

  // To know which conge was selected when the dialog got opened (to be able to update the row)
  const [activeId, setActiveId] = useState(-1)

  const validateCongeDialogHandler = useBoolean(false)
  const rejectCongeDialogHandler = useBoolean(false)

  const updateCongeMutation = useUpdateCongeMutation()

  function openValidateCongeDialog(demande: CongesResponse) {
    // Validate only if the date of the conge is in the past
    if (differenceInDays(new Date(), new Date(demande.date_debut)) > 0) {
      toast.error("Vous ne pouvez pas valider une date de congé antérieure à la date du jour")
    } else {
      setActiveId(demande.id)
      validateCongeDialogHandler.setTrue()
    }
  }

  function openRejectCongeDialog(demande: CongesResponse) {
    setActiveId(demande.id)
    rejectCongeDialogHandler.setTrue()
  }

  function validateConge() {
    updateCongeMutation.mutate({
      id: activeId,
      statut: "valide",
    })
  }

  function rejectConge() {
    updateCongeMutation.mutate({
      id: activeId,
      statut: "rejete",
    })
  }

  function ManagerStatut(manager = "", statut = "") {
    if (manager && statut) return (
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography fontSize="0.8rem">{manager}</Typography>
        {statut === "en_attente" && <Schedule color="action" sx={{ fontSize: "1.2rem" }} titleAccess="En attente" />}
        {statut === "valide" && <CheckCircle color="success" sx={{ fontSize: "1.2rem" }} titleAccess="Validé" />}
        {statut === "rejete" && <Cancel color="error" sx={{ fontSize: "1.2rem" }} titleAccess="Rejeté" />}
      </Stack>
    )

    return <Typography>-</Typography>
  }

  function CongesList() {
    if (congesQuery.isLoading) {
      return <Loader />
    }

    if (congesQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
          sx={{ mt: 8 }}
        >
          Une erreur est survenue
        </Alert>
      )
    }

    if (congesQuery.data.count === 0) {
      return (
        <Alert
          severity="success"
          variant="standard"
          sx={{ mt: 8 }}
        >
          Aucune demande en attente
        </Alert>
      )
    }

    return (
      <>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Membre</TableCell>
                <TableCell>Date demande</TableCell>
                <TableCell>Periode</TableCell>
                <TableCell>Nombre de jours</TableCell>
                <TableCell>Commentaire</TableCell>
                <TableCell>Manager 1</TableCell>
                <TableCell>Manager 2</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {congesQuery.data.results.map(demande => (
                <TableRow key={demande.id}>
                  <TableCell>
                    <MembrePopup membreId={demande.membre.id}>
                      <Stack direction="row" alignItems="center">
                        <Avatar src={demande.membre.photo} sx={{ mr: 2 }} />
                        <Stack direction="column" alignItems="flex-start">
                          <Typography fontSize="1rem">{demande.membre.first_name + " " + demande.membre.last_name}</Typography>
                          <Stack direction="row" alignItems="center">
                            <Work htmlColor="#666666" sx={{ fontSize: "0.9rem", mr: 1 }} />
                            <Typography fontSize="0.8rem" color="#666666">{demande.membre.poste_nom || "-"}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </MembrePopup>
                  </TableCell>
                  <TableCell>{standardDateFormat(demande.date_demande)} | {standardTimeFormat(demande.date_demande)}</TableCell>
                  <TableCell>
                    <Stack spacing={2} direction="row" alignItems="center">
                      {standardDateFormat(demande.date_debut)}
                      <ArrowRight color="action" />
                      {standardDateFormat(demande.date_fin)}
                    </Stack>
                  </TableCell>
                  <TableCell>{demande.nombre_jours}</TableCell>
                  <TableCell>{demande.commentaire}</TableCell>
                  <TableCell>{ManagerStatut(demande.manager1 || "", demande.statut_manager1)}</TableCell>
                  <TableCell>{ManagerStatut(demande.manager2 || "", demande.statut_manager2)}</TableCell>
                  <TableCell>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Button color="success" onClick={() => openValidateCongeDialog(demande)}>Valider</Button>
                      <Button color="error" onClick={() => openRejectCongeDialog(demande)}>Rejeter</Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <MainDialog
          isOpen={validateCongeDialogHandler.value}
          closeFn={validateCongeDialogHandler.setFalse}
          title="Confirmation"
          content="Voulez-vous vraiment valider la demande de congé ?"
          agree={{
            text: "Oui",
            fn: validateConge
          }}
          disagree={{
            text: "Non",
            fn: validateCongeDialogHandler.setFalse
          }}
        />

        <MainDialog
          isOpen={rejectCongeDialogHandler.value}
          closeFn={rejectCongeDialogHandler.setFalse}
          title="Confirmation"
          content="Voulez-vous vraiment rejeter la demande de congé ?"
          agree={{
            text: "Oui",
            fn: rejectConge
          }}
          disagree={{
            text: "Non",
            fn: rejectCongeDialogHandler.setFalse
          }}
        />

        <MainPagination
          page={pagination.page}
          numberOfPages={pagination.countPages(congesQuery.data.count, congesQuery.data.count_per_page)}
          changePage={pagination.changePage}
        />
      </>
    )
  }

  return (
    <Box>
      <MFCard variant="outlined">
        <Stack spacing={2} direction="row" m={6}>
          <MainFilter
            magasinFilter={magasinFilter}
            membreFilter={membreFilter}
            dateStartFilter={dateStartFilter}
            dateEndFilter={dateEndFilter}
          />
        </Stack>
      </MFCard>

      {CongesList()}
    </Box>
  )
}
