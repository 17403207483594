import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_ABSENCES } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type UpdateAbsenceRequest = {
  id: number
  statut: "en_attente" | "justifie" | "non_justifie" | "annule"
}

export type UpdateAbsenceResponse = {
  id: number
  statut: "en_attente" | "justifie" | "non_justifie" | "annule"
}

export type UpdateAbsenceError = {
  detail: "Authentication credentials were not provided." | undefined
}

export default async function updateAbsence(params: UpdateAbsenceRequest) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "PATCH",
    url: API_ABSENCES + params.id + "/",
    data: params
  })

  return response.data as UpdateAbsenceResponse
}
