import "static/styles/personnels.scss"
import { Alert, Box, Grid, Stack } from "@mui/material"
import { useDocumentTitle } from "usehooks-ts"
import usePagination from "hooks/usePagination"
import Loader from "components/commun/ui/Loader"
import MembreCard from "./components/MembreCard"
import MainPagination from "components/commun/MainPagination"
import useMembresQuery from "hooks/queries/membres/useMembresQuery"
import MainFilter from "components/commun/MainFilter"
import useFilters from "hooks/useFilters"
import MFCard from "components/commun/ui/MFCard"

export default function Personnels() {

  useDocumentTitle("Mafranchise | Personnels")

  const pagination = usePagination(1)
  const {
    magasinFilter,
    posteFilter,
  } = useFilters(() => pagination.changePage(1))

  const membresQuery = useMembresQuery({
    key: {
      p: pagination.page,
      magasin: Number(magasinFilter.value.id) || "",
      poste: Number(posteFilter.value.id) || "",
    }
  })

  function MembresList() {
    if (membresQuery.isLoading) {
      return <Loader />
    }

    if (membresQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
        >
          Une erreur est survenue
        </Alert>
      )
    }

    if (membresQuery.data.count === 0) {
      return (
        <Alert
          severity="warning"
          variant="standard"
        >
          Aucun employé trouvé
        </Alert>
      )
    }

    return (
      <div>
        <Grid container spacing={10} mt={0}>
          {membresQuery.data.results.map(membre => {
            return (
              <Grid item xs={4} key={membre.id}>
                <MembreCard membre={membre} />
              </Grid>
            )
          })}
        </Grid>

        <MainPagination
          page={pagination.page}
          numberOfPages={pagination.countPages(membresQuery.data.count, membresQuery.data.count_per_page)}
          changePage={pagination.changePage}
        />
      </div>
    )
  }

  return (
    <Box>
      <MFCard variant="outlined">
        <Stack spacing={2} direction="row" m={6}>
          <MainFilter
            magasinFilter={magasinFilter}
            posteFilter={posteFilter}
          />
        </Stack>
      </MFCard>

      {MembresList()}
    </Box>
  )
}
