import { Work } from "@mui/icons-material"
import { Alert, Avatar, Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import MainFilter from "components/commun/MainFilter"
import MainPagination from "components/commun/MainPagination"
import Loader from "components/commun/ui/Loader"
import MFCard from "components/commun/ui/MFCard"
import MembrePopup from "components/personnels/components/MembrePopup"
import useRetardsQuery from "hooks/queries/assiduite/useRetardsQuery"
import useFilters from "hooks/useFilters"
import usePagination from "hooks/usePagination"
import { serverDateFormat, standardDateFormat, standardTimeFormat } from "utils/datetimeUtils"

export default function Retards() {

  const pagination = usePagination(1)
  const {
    magasinFilter,
    membreFilter,
    dateStartFilter,
    dateEndFilter,
  } = useFilters(() => pagination.changePage(1))

  const retardsQuery = useRetardsQuery({
    key: {
      p: pagination.page,
      magasin: Number(magasinFilter.value.id) || "",
      personel: Number(membreFilter.value.id) || "",
      date_start: serverDateFormat(dateStartFilter.value) || "",
      date_end: serverDateFormat(dateEndFilter.value) || "",
    }
  })

  function RetardsList() {
    if (retardsQuery.isLoading) {
      return <Loader />
    }

    if (retardsQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
          sx={{ mt: 8 }}
        >
          Une erreur est survenue
        </Alert>
      )
    }

    if (retardsQuery.data.count === 0) {
      return (
        <Alert
          severity="warning"
          variant="standard"
          sx={{ mt: 8 }}
        >
          La liste des retards est vide
        </Alert>
      )
    }

    return (
      <>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Membre</TableCell>
                <TableCell>Date retard</TableCell>
                <TableCell>Heure arrivée</TableCell>
                <TableCell>Durée</TableCell>
                <TableCell>Commentaire</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {retardsQuery.data.results.map(retard => (
                <TableRow key={retard.id}>
                  <TableCell>
                    <MembrePopup membreId={retard.membre.id}>
                      <Stack direction="row" alignItems="center">
                        <Avatar src={retard.membre.photo} sx={{ mr: 2 }} />
                        <Stack direction="column" alignItems="flex-start">
                          <Typography fontSize="1rem">{retard.membre.first_name + " " + retard.membre.last_name}</Typography>
                          <Stack direction="row" alignItems="center">
                            <Work htmlColor="#666666" sx={{ fontSize: "0.9rem", mr: 1 }} />
                            <Typography fontSize="0.8rem" color="#666666">{retard.membre.poste_nom || "-"}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </MembrePopup>
                  </TableCell>
                  <TableCell>{standardDateFormat(retard.heure_arrivee)}</TableCell>
                  <TableCell>{standardTimeFormat(retard.heure_arrivee)}</TableCell>
                  <TableCell>{retard.duree} min</TableCell>
                  <TableCell>{retard.commentaire}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <MainPagination
          page={pagination.page}
          numberOfPages={pagination.countPages(retardsQuery.data.count, retardsQuery.data.count_per_page)}
          changePage={pagination.changePage}
        />
      </>
    )
  }

  return (
    <Box>
      <MFCard variant="outlined">
        <Stack spacing={2} direction="row" m={6}>
          <MainFilter
            magasinFilter={magasinFilter}
            membreFilter={membreFilter}
            dateStartFilter={dateStartFilter}
            dateEndFilter={dateEndFilter}
          />
        </Stack>
      </MFCard>

      {RetardsList()}
    </Box>
  )
}
