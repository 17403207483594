import { useQuery } from "@tanstack/react-query"
import planning, { PlanningError, PlanningRequest } from "api/planning/planning"
import { AxiosError } from "axios"
import { KEY_PLANNING } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { stripEmptyKeys } from "utils/queryUtils"

export default function usePlanningQuery(args: CustomQueryArgs<PlanningRequest> = {}) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_PLANNING, { paginated: true, ...strippedKey }] : [KEY_PLANNING],
    queryFn: () => planning(strippedKey),
    onError: (err: AxiosError<PlanningError>) => err,
    ...args.options
  })
}
