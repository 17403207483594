import { Alert, Box, Button, Chip, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { URL_SEND_NOTIFICATIONS } from "constants/urls"
import useNotificationsQuery from "hooks/queries/notifications/useNotificationsQuery"
import { useNavigate } from "react-router-dom"
import { standardDateFormat, standardTimeFormat } from "utils/datetimeUtils"
import usePagination from "hooks/usePagination"
import MainPagination from "components/commun/MainPagination"
import Loader from "components/commun/ui/Loader"
import { DoneAll, Schedule, WarningAmber } from "@mui/icons-material"

export default function Notifications() {

  const navigate = useNavigate()
  const pagination = usePagination(1)
  const notificationsQuery = useNotificationsQuery({
    key: {
      p: pagination.page,
    }
  })

  function NotificationTypeChip(type: string) {
    switch (type) {
    case "absence":
      return <Chip variant="outlined" label="Absence" color="error" />
    case "conge":
      return <Chip variant="outlined" label="Congé" color="info" />
    case "document":
      return <Chip variant="outlined" label="Document" color="success" />
    case "autre":
      return <Chip variant="outlined" label="Autre" color="secondary" />
    default:
      return <Chip variant="outlined" label="-" color="default" />
    }
  }

  if (notificationsQuery.isLoading) {
    return <Loader />
  }

  if (notificationsQuery.isError) {
    return (
      <Alert
        severity="error"
        variant="standard"
      >
        Une erreur est survenue
      </Alert>
    )
  }

  if (notificationsQuery.data.count === 0) {
    return (
      <Alert
        severity="warning"
        variant="standard"
        sx={{ mt: 8 }}
      >
        La liste des notifications est vide
      </Alert>
    )
  }

  return (
    <Box>
      <Stack direction="row" justifyContent="flex-end" spacing={4} mb={6}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(URL_SEND_NOTIFICATIONS)}
        >
          Créer une nouvelle notification
        </Button>
      </Stack>

      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Date envoi</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Categorie</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>Android reçu</TableCell>
              <TableCell>IOS reçu</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationsQuery.data.results.map(token => (
              <TableRow key={token.id}>
                <TableCell>{standardDateFormat(token.created_at)} | {standardTimeFormat(token.created_at)}</TableCell>
                <TableCell>{token.type_message}</TableCell>
                <TableCell>{NotificationTypeChip(token.message.split("@@")[1])}</TableCell>
                <TableCell>{token.message.split("@@")[2]}</TableCell>
                <TableCell>
                  {token.statut_notif === "EN COURS" && <Schedule color="action" fontSize="medium" titleAccess="En cours" />}
                  {token.statut_notif === "TERMINE" && <DoneAll color="success" fontSize="medium" titleAccess="Envoyé" />}
                  {token.statut_notif === "ECHEC" && <WarningAmber color="error" fontSize="medium" titleAccess="Non envoyé" />}
                </TableCell>
                <TableCell>{token.nbr_android_recu}</TableCell>
                <TableCell>{token.nbr_ios_recu}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MainPagination
        page={pagination.page}
        numberOfPages={pagination.countPages(notificationsQuery.data.count, notificationsQuery.data.count_per_page)}
        changePage={pagination.changePage}
      />
    </Box>
  )
}
