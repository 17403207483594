import { useQuery } from "@tanstack/react-query"
import absences, { AbsencesError, AbsencesRequest } from "api/assiduite/absences"
import { AxiosError } from "axios"
import { KEY_ABSENCES } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { stripEmptyKeys } from "utils/queryUtils"

export default function useAbsencesQuery(args: CustomQueryArgs<AbsencesRequest> = {}) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_ABSENCES, { paginated: true, ...strippedKey }] : [KEY_ABSENCES],
    queryFn: () => absences(strippedKey),
    onError: (err: AxiosError<AbsencesError>) => err,
    ...args.options
  })
}
