import { useQuery } from "@tanstack/react-query"
import planningDetails, { PlanningDetailsError, PlanningDetailsRequest } from "api/planning/planningDetails"
import { AxiosError } from "axios"
import { KEY_PLANNING_DETAILS } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { stripEmptyKeys } from "utils/queryUtils"

export default function usePlanningDetailsQuery(args: CustomQueryArgs<PlanningDetailsRequest> = {}) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_PLANNING_DETAILS, strippedKey] : [KEY_PLANNING_DETAILS],
    queryFn: () => planningDetails(strippedKey),
    onError: (err: AxiosError<PlanningDetailsError>) => err,
    ...args.options
  })
}
