import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_MEMBRES } from "constants/endpoints"
import { Pagination } from "constants/types"
import { Cookies } from "react-cookie"

export type MembresRequest = {
  p?: number
  poste?: number | ""
  magasin?: number | ""
  responsable?: string
}

export type MembresResponse = {
  id: number
  identifiant: string
  telephone: string
  last_name: string
  first_name: string
  adresse: string
  ville: string
  ville_nom: string
  cin: string
  document_cin: string
  cnss: string
  date_naissance: Date
  date_embauche: Date
  poste: string
  poste_nom: string
  magasin: string
  magasin_nom: string
  responsable: string
  date_depart: Date
  statut: string
  situation_familiale: string
  photo: string
  created_at: Date
  updated_at: Date
}

export type MembresError = {
  detail: "Authentication credentials were not provided." | "Invalid page." | undefined
}

export default async function membres(params: MembresRequest = {}) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_MEMBRES,
    params: {
      si_paginated: "True",
      ...params
    }
  })

  return response.data as Pagination<MembresResponse>
}
