import { useQueryClient, useMutation } from "@tanstack/react-query"
import updatePlanning, { UpdatePlanningError, UpdatePlanningRequest } from "api/planning/updatePlanning"
import { AxiosError } from "axios"
import { KEY_PLANNING, KEY_PLANNING_DETAILS } from "constants/queryKeys"
import toast from "react-hot-toast"

export default function useUpdatePlanningMutation() {

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: UpdatePlanningRequest) => updatePlanning(data),
    onMutate: () => {
      toast.loading("Modification du planning en cours..", {
        id: "updatePlanning",
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [KEY_PLANNING] })
      await queryClient.invalidateQueries({ queryKey: [KEY_PLANNING_DETAILS] })
      toast.success("Planning modifié avec succès", {
        id: "updatePlanning",
      })
    },
    onError: (err: AxiosError<UpdatePlanningError>) => {
      if (err.response?.data.semaine_started_error) {
        toast.error("Vous ne pouvez pas modifier un planning déjà en cours", {
          id: "updatePlanning",
        })
      } else {
        toast.error("Une erreur est survenue lors de la modification du planning", {
          id: "updatePlanning",
        })
      }
    },
  })
}
