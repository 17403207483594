import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_CONGES } from "constants/endpoints"
import { MembreSubType, Pagination } from "constants/types"
import { Cookies } from "react-cookie"

export type CongeStatus = "en_attente" | "valide" | "rejete"

export type CongesRequest = {
  p?: number
  statut?: CongeStatus
  magasin?: number | ""
  personel?: number | ""
  date_start?: string | ""
  date_end?: string | ""
}

export type CongesResponse = {
  id: number
  date_debut: Date
  date_fin: Date
  nombre_jours: number
  date_demande: Date
  statut: CongeStatus
  commentaire: string
  created_at: Date
  membre: MembreSubType
  manager1: string | null
  manager2: string | null
  statut_manager1: CongeStatus
  statut_manager2: CongeStatus
}

export type CongesError = {
  detail: "Authentication credentials were not provided." | "Invalid page." | undefined
}

export default async function conges(params: CongesRequest = {}) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_CONGES,
    params: {
      si_paginated: "True",
      ...params
    }
  })

  return response.data as Pagination<CongesResponse>
}
