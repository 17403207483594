import { createTheme } from "@mui/material/styles"
import { frFR } from "@mui/material/locale"

const mafranchiseTheme = createTheme({
  spacing: 4,
  palette: {
    mode: "light",
    primary: {
      main: "#851123"
    },
    secondary: {
      main: "#7C47F2"
    },
    info: {
      main: "#2487F2"
    },
    success: {
      main: "#3fa940"
    },
    warning: {
      main: "#FBCB73"
    },
    error: {
      main: "#D93B69"
    }
  },
  typography: {
    fontFamily: "'Open Sans', roboto",
    htmlFontSize: 16,
    h1: {
      fontSize: "4rem",
      fontWeight: 600
    },
    h2: {
      fontSize: "3.5rem",
      fontWeight: 600
    },
    h3: {
      fontSize: "3rem",
      fontWeight: 600
    },
    h4: {
      fontSize: "2.5rem",
      fontWeight: 600
    },
    h5: {
      fontSize: "2rem",
      fontWeight: 600
    },
    h6: {
      fontSize: "1.5rem",
      fontWeight: 600
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 300
    },
    body2: {
      fontSize: "0.8rem",
      fontWeight: 300
    },
    subtitle1: {
      fontSize: "1.2rem",
      fontWeight: 400
    },
    subtitle2: {
      fontSize: "0.8rem",
      fontWeight: 500
    },
    button: {
      textTransform: "uppercase"
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          borderRadius: 4,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: "0.7rem",
          textTransform: "unset",
        }
      }
    },
    MuiChip: {
      defaultProps: {
        size: "small",
      },
    },
    MuiAlert: {
      styleOverrides: {
        standard: {
          border: "1px solid #CCCCCC"
        }
      }
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          style: {
            padding: "1rem"
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        }
      },
      defaultProps: {
        size: "small",
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
        blurOnSelect: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          borderCollapse: "separate",
          borderSpacing: "0 1rem",
          "& thead th": {
            fontWeight: 600,
            fontSize: "0.8rem",
            border: 0,
            color: "#4B6983",
            backgroundColor: "transparent",
            paddingTop: 0,
            paddingBottom: 0,
          },
          "& tbody tr": {
            fontSize: "0.8rem",
            backgroundColor: "#fff",
          },
          "& tbody td": {
            paddingTop: "2rem",
            paddingBottom: "2rem",
            borderTop: "1px solid #E7E9EF",
            borderBottom: "1px solid #E7E9EF",
            "&:first-of-type": {
              borderLeft: "1px solid #E7E9EF",
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            },
            "&:last-child": {
              borderRight: "1px solid #E7E9EF",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            },
          },
        },
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        }
      }
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          border: "1px solid #E7E9EF",
          borderRadius: 10,
          padding: 10,
          "&:before": {
            display: "none",
          },
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.8rem",
          backgroundColor: "#fff",
          color: "#4B6983",
          borderRadius: 10,
          padding: "0.5rem 1rem",
          boxShadow: "0px 0px 10px #00000022",
        }
      }
    },
  }
}, frFR)

export default mafranchiseTheme
