import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_VILLES } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type VillesResponse = {
  id: number
  ville: string
}

export type VillesError = {
  detail: "Authentication credentials were not provided." | undefined
}

export default async function villes() {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_VILLES,
  })

  return response.data as VillesResponse[]
}
