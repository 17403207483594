import { useQuery } from "@tanstack/react-query"
import postes from "api/postes/postes"
import { KEY_POSTES } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"

export default function usePostesQuery(args: CustomQueryArgs<undefined> = {}) {
  return useQuery({
    queryKey: [KEY_POSTES],
    queryFn: () => postes(),
    ...args.options
  })
}
