import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_CREATE_PLANNING } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type PlanningDay = {
  date_jour: string
  jour: string
  shift_matin_heure_debut?: string | null
  shift_matin_heure_fin?: string | null
  shift_apresmidi_heure_debut?: string | null
  shift_apresmidi_heure_fin?: string | null
  si_jour_repos: boolean
}

export type CreatePlanningRequest = {
  employe: number
  date_debut: string,
  date_fin: string,
  planing_jours: PlanningDay[]
}

export type CreatePlanningResponse = {
  id: number
}

export type CreatePlanningError = {
  planning_duplication_error?: any,
  semaine_started_error?: any,
  detail: "Authentication credentials were not provided." | "planning_duplication_error" | undefined
}

export default async function createPlanning(params: CreatePlanningRequest) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "POST",
    url: API_CREATE_PLANNING,
    data: params
  })

  return response.data as CreatePlanningResponse
}
