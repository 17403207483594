import { Phone, Store, Work } from "@mui/icons-material"
import { Avatar, Box, Button, Divider, Stack, Typography } from "@mui/material"
import { MembresResponse } from "api/membres/membres"
import MFCard from "components/commun/ui/MFCard"
import MembrePopup from "./MembrePopup"

type props = {
  membre: MembresResponse
}

export default function MembreCard({ membre }: props) {

  return (
    <>
      <MFCard variant="elevated">
        <Box p={6} className="membre-card">
          <Stack spacing={4} direction="row" alignItems="center" justifyContent="flex-start">
            <Avatar
              variant="rounded"
              alt={membre.first_name + " " + membre.last_name}
              src={membre.photo}
              sx={{ width: 90, height: 90 }}
            />
            <Stack direction="column" alignItems="flex-start" justifyContent="space-around">
              <Typography sx={{ color: "#505F79", fontWeight: 600, fontSize: "1.2rem" }}>{membre.first_name + " " + membre.last_name}</Typography>
              <Stack direction="row" alignItems="center" mt={1.5}>
                <Work htmlColor="#808890" sx={{ fontSize: "1.2rem" }} />
                <Typography color="#808890" sx={{ fontSize: "0.9rem", ml: 1 }}>{membre.poste_nom}</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" mt={1.5}>
                <Store htmlColor="#808890" sx={{ fontSize: "1.2rem" }} />
                <Typography color="#808890" sx={{ fontSize: "0.9rem", ml: 1 }}>{membre.magasin_nom}</Typography>
              </Stack>
            </Stack>
          </Stack>

          <Divider sx={{ mt: 6, mb: 5 }} />

          <Stack spacing={2} direction="column" alignItems="flex-start" justifyContent="flex-start">
            <Stack direction="row" alignItems="center" mb={1.5}>
              <Box sx={{ bgcolor: "#EEF3FF", p: 2, borderRadius: "100%", width: 36, height: 36 }}>
                <Phone htmlColor="#0941BF" sx={{ fontSize: "1.2rem" }} />
              </Box>
              <Typography color="#030202" sx={{ fontSize: "0.9rem", ml: 4 }}>{membre.telephone || "-"}</Typography>
            </Stack>
          </Stack>

          <MembrePopup membreId={membre.id} fullWidth>
            <Button
              fullWidth
              sx={{ mt: 5 }}
              variant="contained"
              size="large"
              type="button"
              disableElevation={true}
            >
              Voir le profil
            </Button>
          </MembrePopup>

        </Box>
      </MFCard>
    </>
  )
}
