import { PriorityHigh } from "@mui/icons-material"
import { Tooltip } from "@mui/material"
import useCongesQuery from "hooks/queries/conges/useCongesQuery"
import { serverDateFormat, standardDateFormat } from "utils/datetimeUtils"

type Props = {
  membreId: number
  dateStart: Date | null
  dateEnd: Date | null
}

export default function MembreCongeInfo({ membreId, dateStart, dateEnd }: Props) {

  const congesQuery = useCongesQuery({
    key: {
      personel: membreId || "",
      date_start: serverDateFormat(dateStart),
      date_end: serverDateFormat(dateEnd),
    }
  })

  if (membreId === 0) {
    return null
  }

  if (congesQuery.isLoading || congesQuery.isError || congesQuery.data.count === 0) {
    return null
  }

  const fullName = `${congesQuery.data.results[0].membre.first_name} ${congesQuery.data.results[0].membre.last_name}`
  const daysCount = congesQuery.data.results[0].nombre_jours
  const startDate = standardDateFormat(congesQuery.data.results[0].date_debut)
  const endDate = standardDateFormat(congesQuery.data.results[0].date_fin)

  if (congesQuery.data.results.some(conge => conge.statut === "en_attente" || conge.statut === "valide")) {
    return (
      <Tooltip
        TransitionProps={{ timeout: 600 }}
        title={`${fullName} a une demande de congé de ${daysCount} jour(s) du ${startDate} au ${endDate}.`}
      >
        <PriorityHigh color="error" />
      </Tooltip>
    )
  }

  return null
}
