import "static/styles/commun/sidebar.scss"
import { Link, useLocation } from "react-router-dom"
import { URL_ABSENCES, URL_ACHATS, URL_ASSIDUITE, URL_CONGES, URL_CREATE_PLANNING, URL_DASHBOARD, URL_DOCUMENTS, URL_MAGASINS, URL_NOTIFICATIONS, URL_PERSONNELS, URL_PLANNING, URL_RETARDS, URL_SEND_NOTIFICATIONS } from "constants/urls"
import { CalendarMonthOutlined, DashboardOutlined, EventAvailable, GroupsOutlined, List, LuggageOutlined, MeetingRoomOutlined, NotificationAddOutlined, NotificationsOutlined, RunningWithErrors, ShoppingCartOutlined, StoreOutlined, TopicOutlined, WarningAmber } from "@mui/icons-material/"
import { Sidebar as ProSidebar, Menu, MenuItem, useProSidebar, SubMenu } from "react-pro-sidebar"
import { ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER } from "constants/roles"
import useInfoProfileQuery from "hooks/queries/auth/useInfoProfileQuery"

const pages = [
  {
    title: "Dashboard",
    href: URL_DASHBOARD,
    icon: DashboardOutlined,
    access: [ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER],
  },
  {
    title: "Planning",
    href: URL_PLANNING,
    icon: CalendarMonthOutlined,
    access: [ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER],
    children: [
      {
        title: "Liste des plannings",
        href: URL_PLANNING,
        icon: List,
        access: [ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER],
      },
      {
        title: "Créer un planning",
        href: URL_CREATE_PLANNING,
        icon: EventAvailable,
        access: [ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER],
      },
    ]
  },
  {
    title: "Magasins",
    href: URL_MAGASINS,
    icon: StoreOutlined,
    access: [ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER],
  },
  {
    title: "Personnels",
    href: URL_PERSONNELS,
    icon: GroupsOutlined,
    access: [ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER],
  },
  {
    title: "Achats",
    href: URL_ACHATS,
    icon: ShoppingCartOutlined,
    access: [ROLE_RESPONSABLE, ROLE_SUPERUSER],
  },
  {
    title: "Assiduité",
    href: URL_ASSIDUITE,
    icon: MeetingRoomOutlined,
    access: [ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER],
    children: [
      {
        title: "Liste des retards",
        href: URL_RETARDS,
        icon: RunningWithErrors,
        access: [ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER],
      },
      {
        title: "Liste des absences",
        href: URL_ABSENCES,
        icon: WarningAmber,
        access: [ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER],
      },
    ]
  },
  {
    title: "Notifications",
    href: URL_NOTIFICATIONS,
    icon: NotificationsOutlined,
    access: [ROLE_RESPONSABLE, ROLE_SUPERUSER],
    children: [
      {
        title: "Liste des notifications",
        href: URL_NOTIFICATIONS,
        icon: List,
        access: [ROLE_RESPONSABLE, ROLE_SUPERUSER],
      },
      {
        title: "Créer une notification",
        href: URL_SEND_NOTIFICATIONS,
        icon: NotificationAddOutlined,
        access: [ROLE_RESPONSABLE, ROLE_SUPERUSER],
      },
    ]
  },
  {
    title: "Congés",
    href: URL_CONGES,
    icon: LuggageOutlined,
    access: [ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER],
  },
  {
    title: "Documents",
    href: URL_DOCUMENTS,
    icon: TopicOutlined,
    access: [ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER],
  },
]

export default function Sidebar() {

  const userInfoQuery = useInfoProfileQuery()

  const location = useLocation()
  const { collapsed } = useProSidebar()

  return (
    <div className="sidebar">
      <ProSidebar
        defaultCollapsed={true}
        width="250px"
        backgroundColor="#fff"
      >
        <div className="top">
          <Link to={URL_DASHBOARD}>
            {collapsed ?
              <img className="logo-small" src={require("static/images/logo-small.png")} alt="Mafranchise" />
              :
              <img className="logo" src={require("static/images/logo-color.png")} alt="Mafranchise" />
            }
          </Link>
        </div>

        <Menu
          closeOnClick={true}
          menuItemStyles={{
            SubMenuExpandIcon() {
              return {
                paddingBottom: "0.25rem",
              }
            },
            button: ({ level, active }) => {
              if (level === 0) {
                return {
                  color: active ? "#a91e23" : "#6a6a6a",
                  backgroundColor: active ? "#66091714" : "#fff",
                  borderRight: active ? "4px solid #a91e23" : "none",
                  fontWeight: 600,
                  fontSize: "0.9rem",
                  paddingTop: "1.8rem",
                  paddingBottom: "1.8rem",
                }
              }
              if (level === 1) {
                return {
                  color: active ? "#a91e23" : "#6a6a6a",
                  backgroundColor: "#f9f9f9",
                  fontWeight: 600,
                  fontSize: "0.75rem",
                  paddingTop: "1.4rem",
                  paddingBottom: "1.4rem",
                  paddingLeft: "1.4rem",
                  paddingRight: "0.4rem",
                }
              }
            },
          }}
        >
          {pages.map((page, index) => {
            if (page.access.includes(userInfoQuery.data?.type_profile as string)) {
              if (page.children) {
                return (
                  <SubMenu
                    key={index}
                    label={page.title}
                    title={page.title}
                    icon={<page.icon sx={{ fontSize: "1.6rem" }} />}
                    active={page.href === URL_DASHBOARD
                      ? location.pathname === URL_DASHBOARD
                      : location.pathname.startsWith(page.href)
                    }
                  >
                    {page.children.map((child, index) => {
                      return (
                        <MenuItem
                          key={index}
                          title={child.title}
                          component={<Link to={child.href} />}
                          icon={<child.icon sx={{ fontSize: "1.4rem" }} />}
                          active={child.href === location.pathname}
                        >
                          {child.title}
                        </MenuItem>
                      )
                    })}
                  </SubMenu>
                )
              } else {
                return (
                  <MenuItem
                    key={index}
                    title={page.title}
                    component={<Link to={page.href} />}
                    icon={<page.icon sx={{ fontSize: "1.6rem" }} />}
                    active={page.href === URL_DASHBOARD
                      ? location.pathname === URL_DASHBOARD
                      : location.pathname.startsWith(page.href)
                    }
                  >
                    {page.title}
                  </MenuItem>
                )
              }
            }
            return null
          })}
        </Menu>
      </ProSidebar>
    </div>
  )
}
