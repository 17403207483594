import { useQuery } from "@tanstack/react-query"
import achats, { AchatsError, AchatsRequest } from "api/achats/achats"
import { AxiosError } from "axios"
import { KEY_ACHATS } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { stripEmptyKeys } from "utils/queryUtils"

export default function useAchatsQuery(args: CustomQueryArgs<AchatsRequest> = {}) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_ACHATS, { paginated: true, ...strippedKey }] : [KEY_ACHATS],
    queryFn: () => achats(strippedKey),
    onError: (err: AxiosError<AchatsError>) => err,
    ...args.options
  })
}
