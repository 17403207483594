import { useQueryClient, useMutation } from "@tanstack/react-query"
import sendNotification, { SendNotificationRequest } from "api/notifications/sendNotification"
import { KEY_NOTIFICATIONS } from "constants/queryKeys"
import toast from "react-hot-toast"

export default function useSendNotificationMutation() {

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: SendNotificationRequest) => sendNotification(data),
    onMutate: () => {
      toast.loading("Envoie de notification en cours..", {
        id: "sendNotification",
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [KEY_NOTIFICATIONS] })
      toast.success("Notification envoyée avec succès", {
        id: "sendNotification",
      })
    },
    onError: () => {
      toast.error("Une erreur est survenue", {
        id: "sendNotification",
      })
    },
  })
}
