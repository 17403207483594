import { useQuery } from "@tanstack/react-query"
import membresNoPagination, { MembresError, MembresRequest } from "api/membres/membresNoPagination"
import { AxiosError } from "axios"
import { KEY_MEMBRES } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { stripEmptyKeys } from "utils/queryUtils"

export default function useMembresNoPaginationQuery(args: CustomQueryArgs<MembresRequest> = {}) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_MEMBRES, strippedKey] : [KEY_MEMBRES],
    queryFn: () => membresNoPagination(strippedKey),
    onError: (err: AxiosError<MembresError>) => err,
    ...args.options
  })
}
