import { Alert, Stack } from "@mui/material"
import Loader from "components/commun/ui/Loader"
import MFCard from "components/commun/ui/MFCard"
import UserStatusCard from "./UserStatusCard"
import { serverDateFormat } from "utils/datetimeUtils"
import useRetardsQuery from "hooks/queries/assiduite/useRetardsQuery"
import MembrePopup from "components/personnels/components/MembrePopup"

export default function RetardsList() {

  const retardsQuery = useRetardsQuery({
    key: {
      date_start: serverDateFormat(new Date()),
      date_end: serverDateFormat(new Date()),
    }
  })

  if (retardsQuery.isLoading) {
    return (
      <MFCard variant="outlined" title="Les retards">
        <Loader />
      </MFCard>
    )
  }

  if (retardsQuery.isError) {
    return (
      <MFCard variant="outlined" title="Les retards">
        <Alert
          severity="error"
          variant="standard"
          sx={{ m: 4 }}
        >
          Une erreur est survenue
        </Alert>
      </MFCard>
    )
  }

  if (retardsQuery.data.count === 0) {
    return (
      <MFCard variant="outlined" title="Les retards">
        <Alert
          severity="success"
          variant="standard"
          sx={{ m: 4 }}
        >
          Aucun retard aujourd'hui
        </Alert>
      </MFCard>
    )
  }

  return (
    <MFCard variant="outlined" title="Les retards">
      <Stack textAlign="center" spacing={4} sx={{ p: 4 }}>
        {retardsQuery.data.results.slice(0, 3).map(retard => (
          <MembrePopup key={retard.id} membreId={retard.membre.id} fullWidth>
            <UserStatusCard
              membre={{
                first_name: retard.membre.first_name,
                last_name: retard.membre.last_name,
                fonction: retard.membre.poste_nom,
                photo: retard.membre.photo
              }}
              status="retard"
              lateTime={retard.duree}
            />
          </MembrePopup>
        ))}
      </Stack>
    </MFCard>
  )
}
