import { Alert, Avatar, Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import MainDialog from "components/commun/MainDialog"
import MainPagination from "components/commun/MainPagination"
import Loader from "components/commun/ui/Loader"
import usePagination from "hooks/usePagination"
import { useState } from "react"
import { useBoolean } from "usehooks-ts"
import { serverDateFormat, standardDateFormat, standardTimeFormat } from "utils/datetimeUtils"
import { DocumentType } from "api/documents/documents"
import useDocumentsQuery from "hooks/queries/documents/useDocumentsQuery"
import useUpdateDocumentMutation from "hooks/queries/documents/useUpdateDocumentMutation"
import useFilters from "hooks/useFilters"
import MainFilter from "components/commun/MainFilter"
import MFCard from "components/commun/ui/MFCard"
import MembrePopup from "components/personnels/components/MembrePopup"
import { Work } from "@mui/icons-material"

export default function DocumentsAttente() {

  const pagination = usePagination(1)
  const {
    magasinFilter,
    membreFilter,
    documentTypeFilter,
    dateStartFilter,
    dateEndFilter,
  } = useFilters(() => pagination.changePage(1))

  const documentsQuery = useDocumentsQuery({
    key: {
      p: pagination.page,
      statut: "en_attente",
      magasin: Number(magasinFilter.value.id) || "",
      personel: Number(membreFilter.value.id) || "",
      type_document: documentTypeFilter.value.id as DocumentType,
      date_start: serverDateFormat(dateStartFilter.value) || "",
      date_end: serverDateFormat(dateEndFilter.value) || "",
    }
  })

  // To know which document was selected when the dialog got opened (to be able to update the row)
  const [activeId, setActiveId] = useState(-1)

  const validateDocumentDialogHandler = useBoolean(false)

  const updateDocumentMutation = useUpdateDocumentMutation()

  function openValidateDocumentDialog(id: number) {
    setActiveId(id)
    validateDocumentDialogHandler.setTrue()
  }

  function validateDocument() {
    updateDocumentMutation.mutate({
      id: activeId,
      statut: "traite",
    })
  }

  function DocumentsList() {
    if (documentsQuery.isLoading) {
      return <Loader />
    }

    if (documentsQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
          sx={{ mt: 8 }}
        >
          Une erreur est survenue
        </Alert>
      )
    }

    if (documentsQuery.data.results.length === 0) {
      return (
        <Alert
          severity="success"
          variant="standard"
          sx={{ mt: 8 }}
        >
          Aucune demande trouvée
        </Alert>
      )
    }

    return (
      <>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Membre</TableCell>
                <TableCell>Date demande</TableCell>
                <TableCell>Type document</TableCell>
                <TableCell>Commentaire</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentsQuery.data.results.map(demande => (
                <TableRow key={demande.id}>
                  <TableCell>
                    <MembrePopup membreId={demande.membre.id}>
                      <Stack direction="row" alignItems="center">
                        <Avatar src={demande.membre.photo} sx={{ mr: 2 }} />
                        <Stack direction="column" alignItems="flex-start">
                          <Typography fontSize="1rem">{demande.membre.first_name + " " + demande.membre.last_name}</Typography>
                          <Stack direction="row" alignItems="center">
                            <Work htmlColor="#666666" sx={{ fontSize: "0.9rem", mr: 1 }} />
                            <Typography fontSize="0.8rem" color="#666666">{demande.membre.poste_nom || "-"}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </MembrePopup>
                  </TableCell>
                  <TableCell>{standardDateFormat(demande.date_demande)} | {standardTimeFormat(demande.date_demande)}</TableCell>
                  <TableCell>{demande.type_document.replace("_", " de ")}</TableCell>
                  <TableCell>{demande.commentaire}</TableCell>
                  <TableCell>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Button color="success" onClick={() => openValidateDocumentDialog(demande.id)}>Traiter</Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <MainDialog
          isOpen={validateDocumentDialogHandler.value}
          closeFn={validateDocumentDialogHandler.setFalse}
          title="Confirmation"
          content="Voulez-vous vraiment valider la demande de document ?"
          agree={{
            text: "Oui",
            fn: validateDocument
          }}
          disagree={{
            text: "Non",
            fn: validateDocumentDialogHandler.setFalse
          }}
        />

        <MainPagination
          page={pagination.page}
          numberOfPages={pagination.countPages(documentsQuery.data.count, documentsQuery.data.count_per_page)}
          changePage={pagination.changePage}
        />
      </>
    )
  }

  return (
    <Box>
      <MFCard variant="outlined">
        <Stack spacing={2} direction="row" m={6}>
          <MainFilter
            magasinFilter={magasinFilter}
            membreFilter={membreFilter}
            dateStartFilter={dateStartFilter}
            dateEndFilter={dateEndFilter}
            documentTypeFilter={documentTypeFilter}
          />
        </Stack>
      </MFCard>

      {DocumentsList()}
    </Box>
  )
}
