import { useQueryClient, useMutation } from "@tanstack/react-query"
import updateDocument, { UpdateDocumentRequest } from "api/documents/updateDocument"
import { KEY_DOCUMENTS } from "constants/queryKeys"
import toast from "react-hot-toast"

export default function useUpdateDocumentMutation() {

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: UpdateDocumentRequest) => updateDocument(data),
    onMutate: () => {
      toast.loading("Traitement en cours..", {
        id: "demandeDocument",
      })
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS, { statut: "en_attente" }] })

      if (response.statut === "traite") {
        await queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS, { statut: "traite" }] })
        toast.success("Demande traitée avec succès", {
          id: "demandeDocument",
        })
      }
    },
    onError: () => {
      toast.error("Une erreur est survenue", {
        id: "demandeDocument",
      })
    },
  })
}
