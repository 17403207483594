import axios from "axios"
import { API_LOGIN } from "constants/endpoints"

export type LoginRequest = {
  username: string
  password: string
}

export type LoginResponse = {
  last_name: string
  first_name: string
  email: string
  type_profile: "employee" | "manager"
  login: {
    access_token: string
    client_id: string
    client_secret: string
    refresh_token: string
  }
}

export type LoginError = {
  detail: "400_wrong_login_or_password" | ""
}

export default async function login(params: LoginRequest | undefined) {
  const response = await axios({
    method: "POST",
    url: API_LOGIN,
    data: {
      source: "web",
      ...params
    }
  })

  return response.data as LoginResponse
}
