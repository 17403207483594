import { useQuery } from "@tanstack/react-query"
import notifications, { NotificationsRequest, NotificationsResponse } from "api/notifications/notifications"
import { AxiosError } from "axios"
import { KEY_NOTIFICATIONS } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { stripEmptyKeys } from "utils/queryUtils"

export default function useNotificationsQuery(args: CustomQueryArgs<NotificationsRequest> = {}) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_NOTIFICATIONS, { paginated: true, ...strippedKey }] : [KEY_NOTIFICATIONS],
    queryFn: () => notifications(strippedKey),
    onError: (err: AxiosError<NotificationsResponse>) => err,
    ...args.options
  })
}
