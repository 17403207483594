import { useQuery } from "@tanstack/react-query"
import villes from "api/villes/villes"
import { KEY_VILLES } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"

export default function useVillesQuery(args: CustomQueryArgs<undefined> = {}) {
  return useQuery({
    queryKey: [KEY_VILLES],
    queryFn: () => villes(),
    ...args.options
  })
}
