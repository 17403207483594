import { Box, Tab, Tabs } from "@mui/material"
import { ReactNode, SyntheticEvent, useState } from "react"
import AbsencesAttente from "./components/AbsencesAttente"
import AbsencesJustifie from "./components/AbsencesJustifie"
import AbsencesNonJustifie from "./components/AbsencesNonJustifie"
import AbsencesAnnule from "./components/AbsencesAnnule"
import { useDocumentTitle } from "usehooks-ts"
import { Assignment, AssignmentInd, AssignmentLate, AssignmentTurnedIn } from "@mui/icons-material"
import AbsencesPointage from "./components/AbsencesPointage"

type Props = {
  children?: ReactNode
  index: number
  value: number
}

function TabPanel({ children, value, index }: Props) {
  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box>{children}</Box>
      )}
    </div>
  )
}

export default function Conges() {

  useDocumentTitle("Mafranchise | Absences")

  const [value, setValue] = useState(0)

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 8 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Absences en attente" icon={<Assignment />} iconPosition="start" />
          <Tab label="Absences justifiées" icon={<AssignmentTurnedIn />} iconPosition="start" />
          <Tab label="Absences non justifiées" icon={<Assignment />} iconPosition="start" />
          <Tab label="Absences annulées" icon={<AssignmentLate />} iconPosition="start" />
          <Tab label="Absences pointage" icon={<AssignmentInd />} iconPosition="start" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AbsencesAttente />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AbsencesJustifie />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AbsencesNonJustifie />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AbsencesAnnule />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <AbsencesPointage />
      </TabPanel>
    </Box>
  )
}
