import "static/styles/auth.scss"
import { useEffect } from "react"
import { Button, CircularProgress, TextField, Typography } from "@mui/material"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { LoginRequest } from "api/auth/login"
import { useDocumentTitle } from "usehooks-ts"
import useLoginMutation from "hooks/queries/auth/useLoginMutation"

export default function Login() {

  useDocumentTitle("Mafranchise | Connexion")

  const form = useForm<LoginRequest>({
    defaultValues: {
      username: "",
      password: ""
    }
  })

  function registerFields() {
    form.register("username", {
      required: "Merci de fournir votre nom d'utilisateur",
    })

    form.register("password", {
      required: "Merci de fournir votre mot de passe"
    })
  }

  registerFields()

  const loginMutation = useLoginMutation()

  const submitForm: SubmitHandler<LoginRequest> = (data) => {
    loginMutation.mutate(data)
  }

  // react-hook-form doesn't support enter button so we have to do it manually
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter") {
        loginMutation.mutate(form.getValues())
      }
    }
    window.addEventListener("keydown", handleKeyDown)

    // cleanup function to remove event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [loginMutation, form])

  return (
    <div className="login" >
      <img className="logo" src={require("static/images/logo-white.png")} alt="Mafranchise" />

      <form onSubmit={form.handleSubmit(submitForm)}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Connectez-vous
        </Typography>

        <Controller
          name="username"
          control={form.control}
          render={(ctx) => (
            <TextField
              {...ctx.field}
              inputProps={{
                form: { autoComplete: "off" }
              }}
              variant="filled"
              fullWidth
              margin="normal"
              type="text"
              label="Nom d'utilisateur"
              error={!!ctx.fieldState.error}
              helperText={ctx.fieldState.error?.message}
            />
          )}
        />

        <Controller
          name="password"
          control={form.control}
          render={(ctx) => (
            <TextField
              {...ctx.field}
              inputProps={{
                form: { autoComplete: "off" }
              }}
              variant="filled"
              fullWidth
              margin="normal"
              type="password"
              label="Mot de passe"
              error={!!ctx.fieldState.error}
              helperText={ctx.fieldState.error?.message}
            />
          )}
        />

        {loginMutation.isError && (
          <Typography sx={{ my: 2, color: "error.main" }}>Votre nom d'utilisateur ou votre mot de passe est incorrecte.</Typography>
        )}

        <Button
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          sx={{ mt: 4 }}
          disabled={loginMutation.isLoading}
        >
          {loginMutation.isLoading
            ? <CircularProgress size={20} color="inherit" />
            : "Login"
          }
        </Button>
      </form>
    </div>
  )
}
