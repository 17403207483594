import { useQuery } from "@tanstack/react-query"
import marques from "api/marques/marques"
import { KEY_MARQUES } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"

export default function useMarquesQuery(args: CustomQueryArgs<undefined> = {}) {
  return useQuery({
    queryKey: [KEY_MARQUES],
    queryFn: () => marques(),
    ...args.options
  })
}
