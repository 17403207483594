import { useQuery } from "@tanstack/react-query"
import conges, { CongesError, CongesRequest } from "api/conges/conges"
import { AxiosError } from "axios"
import { KEY_CONGES } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { stripEmptyKeys } from "utils/queryUtils"

export default function useCongesQuery(args: CustomQueryArgs<CongesRequest> = {}) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_CONGES, { paginated: true, ...strippedKey }] : [KEY_CONGES],
    queryFn: () => conges(strippedKey),
    onError: (err: AxiosError<CongesError>) => err,
    ...args.options
  })
}
