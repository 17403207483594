import { Box, Tab, Tabs } from "@mui/material"
import { ReactNode, SyntheticEvent, useState } from "react"
import CongesAttente from "./components/CongesAttente"
import CongesValidees from "./components/CongesValidees"
import CongesRejetees from "./components/CongesRejetees"
import { useDocumentTitle } from "usehooks-ts"
import { Assignment, AssignmentLate, AssignmentTurnedIn } from "@mui/icons-material"

type Props = {
  children?: ReactNode
  index: number
  value: number
}

function TabPanel({ children, value, index }: Props) {
  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box>{children}</Box>
      )}
    </div>
  )
}

export default function Conges() {

  useDocumentTitle("Mafranchise | Congés")

  const [value, setValue] = useState(0)

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 8 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Demandes en attente" icon={<Assignment />} iconPosition="start" />
          <Tab label="Demandes validées" icon={<AssignmentTurnedIn />} iconPosition="start" />
          <Tab label="Demandes rejetées" icon={<AssignmentLate />} iconPosition="start" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CongesAttente />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CongesValidees />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CongesRejetees />
      </TabPanel>
    </Box>
  )
}
