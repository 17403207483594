import "static/styles/commun/ui/MFCard.scss"
import { ReactElement } from "react"
import { Typography } from "@mui/material"

type Props = {
  children: ReactElement
  title?: string,
  clickable?: boolean
  variant: "outlined" | "elevated"
}

export default function MFCard({ children, title, variant, clickable }: Props) {

  function _head() {
    if (title) return (
      <div className="head">
        <Typography fontWeight={600}>{title}</Typography>
      </div>
    )
  }

  return (
    <div className={`mf-card ${variant === "outlined" ? "outlined" : "elevated"} ${clickable ? "clickable" : ""}`}>

      {_head()}

      {children}
    </div>
  )
}
