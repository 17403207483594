import { Business, Phone, Store } from "@mui/icons-material"
import { Box, Button, Stack, Typography } from "@mui/material"
import { MagasinsResponse } from "api/magasins/magasins"
import MFCard from "components/commun/ui/MFCard"
import { useBoolean } from "usehooks-ts"
import MagasinPopup from "./MagasinPopup"

type props = {
  magasin: MagasinsResponse
}

export default function MagasinCard({ magasin }: props) {

  const magasinPopupHandler = useBoolean(false)

  return (
    <>
      <MFCard variant="elevated">
        <Box p={6} className="magasin-card">
          <Stack spacing={4} direction="row" alignItems="center" justifyContent="flex-start">
            <img
              src={magasin.logo}
              alt={magasin.nom}
              style={{
                width: 120,
                height: 120,
                objectFit: "contain",
                border: "1px solid #d9d9d9",
                borderRadius: 10
              }}
            />
            <Stack direction="column" alignItems="flex-start" justifyContent="space-around">
              <Typography fontWeight={600} mb={3}>{magasin.nom}</Typography>
              <Stack direction="row" alignItems="center" mb={1.5}>
                <Store sx={{ fontSize: "1.2rem" }} />
                <Typography color="#808890" sx={{ fontSize: "0.9rem", ml: 3 }}>{magasin.identifiant_point_vente || "-"}</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" mb={1.5}>
                <Business sx={{ fontSize: "1.2rem" }} />
                <Typography color="#808890" sx={{ fontSize: "0.9rem", ml: 3 }}>{magasin.nom_ville || "-"}</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" mb={1.5}>
                <Phone sx={{ fontSize: "1.2rem" }} />
                <Typography color="#808890" sx={{ fontSize: "0.9rem", ml: 3 }}>{magasin.telephone || "-"}</Typography>
              </Stack>
            </Stack>
          </Stack>

          <Button
            className="mt-3"
            fullWidth
            sx={{ mt: 5 }}
            variant="contained"
            size="large"
            type="button"
            disableElevation={true}
            onClick={magasinPopupHandler.setTrue}
          >
            Voir le magasin
          </Button>
        </Box>
      </MFCard>

      {magasinPopupHandler.value &&
        <MagasinPopup
          magasinId={magasin.id}
          isOpen={magasinPopupHandler.value}
          closeFn={magasinPopupHandler.setFalse}
        />
      }
    </>
  )
}
