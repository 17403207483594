import { Replay } from "@mui/icons-material"
import { Box, Button, Container, Stack, Typography } from "@mui/material"

export default function ErrorFallback() {

  function reloadApp() {
    window.location.href = "/"
  }

  return (
    <div style={{ backgroundColor: "#F8F9FB" }}>
      <Container>
        <Stack direction="column" alignItems="center" justifyContent="center" height="100vh">
          <img style={{ maxWidth: "90%", width: 600 }} src={require("static/images/error-image.png")} alt="Error" />
          <Box mt={12} sx={{ textAlign: "center" }}>
            <Typography variant="h3" mb={6}>Oops ! Un problème est survenu</Typography>
            <Typography variant="subtitle1">Veuillez réessayer ultérieurement ou contacter notre équipe</Typography>
            <Typography variant="subtitle1">d'assistance si le problème persiste.</Typography>
            <Button
              sx={{ mt: 6, px: 5, py: 3 }}
              variant="contained"
              onClick={reloadApp}
            >
              <Replay sx={{ mr: 1 }} fontSize="small" />
              Recharger la platforme
            </Button>
          </Box>
        </Stack>
      </Container>
    </div>
  )
}
