import { Call, Place } from "@mui/icons-material"
import { Alert, Avatar, Box, Grid, Stack, Tooltip, Typography } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Loader from "components/commun/ui/Loader"
import MFCard from "components/commun/ui/MFCard"
import { endOfYear, startOfYear } from "date-fns"
import useAbsencesQuery from "hooks/queries/assiduite/useAbsencesQuery"
import useRetardsQuery from "hooks/queries/assiduite/useRetardsQuery"
import useCongesQuery from "hooks/queries/conges/useCongesQuery"
import useDocumentsQuery from "hooks/queries/documents/useDocumentsQuery"
import useMembreByIdQuery from "hooks/queries/membres/useMembreById"
import { useBoolean } from "usehooks-ts"
import { serverDateFormat, standardDateFormat } from "utils/datetimeUtils"

/**
 * The popup component had to be separated from its handler component which is rendered for every element in each list.
 * Yet still decided to keep them in the same file for simplicity and because they will always be used together.
 * This way, the popup will be rendered only once (only when the user clicks on the button to open it)
 * and not for every element in the list to avoid multiple requests and improve performance.
*/

type PopupProps = {
  membreId: number
  isOpen: boolean
  closeFn: () => void
}

function PopupContent({ membreId, isOpen, closeFn }: PopupProps) {

  const membreByIdQuery = useMembreByIdQuery({
    key: {
      id: membreId
    },
    options: {
      enabled: isOpen
    }
  })

  const retardsQuery = useRetardsQuery({
    key: {
      personel: membreId,
      date_start: serverDateFormat(startOfYear(new Date())),
      date_end: serverDateFormat(endOfYear(new Date())),
    },
    options: {
      enabled: isOpen
    }
  })

  const absencesQuery = useAbsencesQuery({
    key: {
      personel: membreId,
      date_start: serverDateFormat(startOfYear(new Date())),
      date_end: serverDateFormat(endOfYear(new Date())),
    },
    options: {
      enabled: isOpen
    }
  })

  const congesQuery = useCongesQuery({
    key: {
      personel: membreId,
      statut: "en_attente"
    },
    options: {
      enabled: isOpen
    }
  })

  const documentsQuery = useDocumentsQuery({
    key: {
      personel: membreId,
      statut: "en_attente"
    },
    options: {
      enabled: isOpen
    }
  })

  function MembreContent() {
    if (membreByIdQuery.isLoading || retardsQuery.isLoading || absencesQuery.isLoading || congesQuery.isLoading || documentsQuery.isLoading) {
      return <Loader />
    }

    if (membreByIdQuery.isError || retardsQuery.isError || absencesQuery.isError || congesQuery.isError || documentsQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
        >
          Une erreur est survenue
        </Alert>
      )
    }

    return (
      <Grid container height="100%" spacing={5}>
        <Grid item xs={4}>
          <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ backgroundColor: "#F6F9FA", height: "100%", p: 8 }}>
            <Box mb={4}>
              <Avatar
                variant="square"
                src={membreByIdQuery.data?.photo}
                sx={{ width: "100%", height: "auto", mb: 3, borderRadius: 1 }}
                style={{ aspectRatio: "1/1" }}
                alt={membreByIdQuery.data?.first_name + " " + membreByIdQuery.data?.last_name}
              />
              <Typography variant="h6" fontWeight={600}>{membreByIdQuery.data?.first_name + " " + membreByIdQuery.data?.last_name}</Typography>
              <Typography variant="body2">{membreByIdQuery.data?.poste_nom}</Typography>
            </Box>

            <Box>
              <Stack direction="row" alignItems="start" sx={{ mt: 1 }}>
                <Place color="primary" sx={{ fontSize: "1.2rem", mt: 0.6 }} />
                <Typography sx={{ fontSize: "1rem", ml: 2 }}>{membreByIdQuery.data?.adresse || "-"}</Typography>
              </Stack>
              <Stack direction="row" alignItems="start" sx={{ mt: 1 }}>
                <Call color="primary" sx={{ fontSize: "1.2rem", mt: 0.6 }} />
                <Typography sx={{ fontSize: "1rem", ml: 2 }}>{membreByIdQuery.data?.telephone || "-"}</Typography>
              </Stack>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={8}>
          <Stack sx={{ height: "100%" }} direction="column" justifyContent="flex-end">
            <Stack direction="column">
              {congesQuery.data && congesQuery.data?.count > 0 && (
                <Alert severity="info" variant="standard" sx={{ mb: 4 }}>
                  {congesQuery.data?.count} demande(s) de congé de en attente !
                </Alert>
              )}
              {documentsQuery.data && documentsQuery.data?.count > 0 && (
                <Alert severity="info" variant="standard" sx={{ mb: 4 }}>
                  {documentsQuery.data?.count} demande(s) de document de en attente !
                </Alert>
              )}
            </Stack>

            <Grid container spacing={5} sx={{ mb: 4 }}>
              <Grid item xs={6}>
                <MFCard variant="outlined">
                  <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ p: 4, backgroundColor: "#FBCB7322", flex: 1 }}>
                    <Typography variant="h4" color="#FBCB73" fontWeight={700}>
                      {retardsQuery.data?.count && retardsQuery.data?.count < 9 ? "0" + retardsQuery.data?.count : retardsQuery.data?.count}
                    </Typography>
                    <Typography variant="body1" color="#e7b55a" fontWeight={500} ml={4}>Retards durant cette année</Typography>
                  </Stack>
                </MFCard>
              </Grid>
              <Grid item xs={6}>
                <MFCard variant="outlined">
                  <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ p: 4, backgroundColor: "#d84d4d22", flex: 1 }}>
                    <Typography variant="h4" color="#D84D4D" fontWeight={700}>
                      {absencesQuery.data?.count && absencesQuery.data?.count < 9 ? "0" + absencesQuery.data?.count : absencesQuery.data?.count}
                    </Typography>
                    <Typography variant="body1" color="#D84D4D" fontWeight={500} ml={4}>Absences durant cette année</Typography>
                  </Stack>
                </MFCard>
              </Grid>
            </Grid>

            <Box sx={{ backgroundColor: "#F6F9FA", p: 6 }}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr style={{ paddingBottom: 40 }}>
                    <td style={{ width: "33%", padding: "18px 0" }}>
                      <Typography color="#236BAA" fontWeight={600}>Magasin</Typography>
                      <Typography>{membreByIdQuery.data?.magasin_nom || "-"}</Typography>
                    </td>
                    <td style={{ width: "33%", padding: "18px 0" }}>
                      <Typography color="#236BAA" fontWeight={600}>CNSS</Typography>
                      <Typography>{membreByIdQuery.data?.cnss || "-"}</Typography>
                    </td>
                    <td style={{ width: "33%", padding: "18px 0" }}>
                      <Typography color="#236BAA" fontWeight={600}>Situation Familiale</Typography>
                      <Typography>{membreByIdQuery.data?.situation_familiale || "-"}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "33%", padding: "18px 0" }}>
                      <Typography color="#236BAA" fontWeight={600}>Ville</Typography>
                      <Typography>{membreByIdQuery.data?.ville_nom || "-"}</Typography>
                    </td>
                    <td style={{ width: "33%", padding: "18px 0" }}>
                      <Typography color="#236BAA" fontWeight={600}>CIN number</Typography>
                      <Typography>{membreByIdQuery.data?.cin || "-"}</Typography>
                    </td>
                    <td style={{ width: "33%", padding: "18px 0" }}>
                      <Typography color="#236BAA" fontWeight={600}>Date de naissance</Typography>
                      <Typography>{standardDateFormat(membreByIdQuery.data?.date_naissance || new Date()) || "-"}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "33%", padding: "18px 0" }}>
                      <Typography color="#236BAA" fontWeight={600}>Responsable</Typography>
                      <Typography>{membreByIdQuery.data?.responsable || "-"}</Typography>
                    </td>
                    <td style={{ width: "33%", padding: "18px 0" }}>
                      <Typography color="#236BAA" fontWeight={600}>ID</Typography>
                      <Typography>{membreByIdQuery.data?.identifiant || "-"}</Typography>
                    </td>
                    <td style={{ width: "33%", padding: "18px 0" }}>
                      <Typography color="#236BAA" fontWeight={600}>Date d'embauche</Typography>
                      <Typography>{standardDateFormat(membreByIdQuery.data?.date_embauche || new Date()) || "-"}</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={isOpen}
      onClose={closeFn}
    >
      <DialogContent sx={{ minWidth: 800 }}>
        {MembreContent()}
      </DialogContent>
    </Dialog>
  )
}

type Props = {
  membreId: number
  children: React.ReactNode
  fullWidth?: boolean
}

export default function MembrePopup({ membreId, children, fullWidth }: Props) {

  const membrePopupHandler = useBoolean(false)

  return (
    <>
      <Tooltip title="Voir les détails du membre" >
        <Box
          onClick={membrePopupHandler.setTrue}
          sx={{
            cursor: "pointer",
            width: fullWidth ? "100%" : "fit-content",
          }}
        >
          {children}
        </Box>
      </Tooltip>

      {membrePopupHandler.value && (
        <PopupContent
          membreId={membreId}
          isOpen={membrePopupHandler.value}
          closeFn={membrePopupHandler.setFalse}
        />
      )}
    </>
  )
}
