import { useQuery } from "@tanstack/react-query"
import tokens, { TokensRequest, TokensResponse } from "api/notifications/tokens"
import { AxiosError } from "axios"
import { KEY_TOKENS } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { stripEmptyKeys } from "utils/queryUtils"

export default function useTokensQuery(args: CustomQueryArgs<TokensRequest> = {}) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_TOKENS, strippedKey] : [KEY_TOKENS],
    queryFn: () => tokens(strippedKey),
    onError: (err: AxiosError<TokensResponse>) => err,
    ...args.options
  })
}
