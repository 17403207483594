import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

type UsePaginationOutput = {
  page: number
  changePage: (value: number) => void
  incrementPage: () => void
  decrementPage: () => void
  countPages: (count: number, countPerPage: number) => number
}

export default function usePagination(initialPage: number, syncUrl?: boolean): UsePaginationOutput {

  const [page, setPage] = useState(initialPage || 1)
  const [searchParams, setSearchParams] = useSearchParams()

  // get page number from url and use it as initial page if it exists
  useEffect(() => {
    if (syncUrl) setPage(parseInt(searchParams.get("p") || "1"))
  }, [searchParams, syncUrl])

  // scroll to top of page when changing page
  const scrollToTop = () => window.scrollTo(0, 0)

  function changePage (value: number) {
    setPage(value)
    scrollToTop()
    if (syncUrl) setSearchParams({ p: value.toString() })
  }

  function incrementPage () {
    setPage(prevPage => prevPage + 1)
    scrollToTop()
    if (syncUrl) setSearchParams({ p: (page + 1).toString() })
  }

  function decrementPage () {
    setPage(prevPage => prevPage - 1)
    scrollToTop()
    if (syncUrl) setSearchParams({ p: (page - 1).toString() })
  }

  // calculate number of pages based on count and countPerPage
  function countPages (count: number, countPerPage: number) {
    if (countPerPage > 0 && count > 0) {
      return Math.ceil(count / countPerPage)
    }
    return 0
  }

  return { page, changePage, incrementPage, decrementPage, countPages }
}
