import { useQuery } from "@tanstack/react-query"
import magasinById, { MagasinByIdRequest, MagasinByIdError } from "api/magasins/magasinById"
import { AxiosError } from "axios"
import { KEY_MAGASINS } from "constants/queryKeys"
import { CustomQueryArgs } from "constants/types"
import { stripEmptyKeys } from "utils/queryUtils"

export default function useMagasinByIdQuery(args: CustomQueryArgs<MagasinByIdRequest> = {}) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_MAGASINS, strippedKey] : [KEY_MAGASINS],
    queryFn: () => magasinById(strippedKey),
    onError: (err: AxiosError<MagasinByIdError>) => err,
    ...args.options
  })
}
