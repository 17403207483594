import { useQueryClient, useMutation } from "@tanstack/react-query"
import updateConge, { UpdateCongeRequest } from "api/conges/updateConge"
import { KEY_CONGES } from "constants/queryKeys"
import toast from "react-hot-toast"

export default function useUpdateCongeMutation() {

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: UpdateCongeRequest) => updateConge(data),
    onMutate: () => {
      toast.loading("Traitement en cours..", {
        id: "demandeConge",
      })
    },
    onSuccess: async (response, variables) => {
      await queryClient.invalidateQueries({ queryKey: [KEY_CONGES, { statut: "en_attente" }] })

      if (variables.statut === "valide") {
        await queryClient.invalidateQueries({ queryKey: [KEY_CONGES, { statut: "valide" }] })
        toast.success("Demande validée avec succès", {
          id: "demandeConge",
        })
      } else if (variables.statut === "rejete") {
        await queryClient.invalidateQueries({ queryKey: [KEY_CONGES, { statut: "rejete" }] })
        toast.success("Demande rejetée avec succès", {
          id: "demandeConge",
        })
      }
    },
    onError: () => {
      toast.error("Une erreur est survenue", {
        id: "demandeConge",
      })
    },
  })
}
