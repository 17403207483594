import { Call, ChevronRight, Groups, Place, Work } from "@mui/icons-material"
import { Alert, Avatar, Box, Divider, Grid, Typography } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import { Stack } from "@mui/system"
import Loader from "components/commun/ui/Loader"
import MembrePopup from "components/personnels/components/MembrePopup"
import useMagasinByIdQuery from "hooks/queries/magasins/useMagasinByIdQuery"
import useMembresNoPaginationQuery from "hooks/queries/membres/useMembresNoPaginationQuery"

type Props = {
  magasinId: number
  isOpen: boolean
  closeFn: () => void
}

export default function MagasinPopup({ isOpen, closeFn, magasinId }: Props) {

  const magasinByIdQuery = useMagasinByIdQuery({
    key: {
      id: magasinId
    },
    options: {
      enabled: isOpen
    }
  })

  const membresQuery = useMembresNoPaginationQuery({
    key: {
      magasin: magasinId
    },
    options: {
      enabled: isOpen
    }
  })

  function MembresList() {
    if (membresQuery.isLoading) {
      return <Loader />
    }

    if (membresQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
        >
          Une erreur est survenue
        </Alert>
      )
    }

    if (membresQuery.data.length === 0) {
      return (
        <Alert
          severity="warning"
          variant="standard"
        >
          La liste des membres est vide
        </Alert>
      )
    }

    return membresQuery.data?.map(membre => (
      <MembrePopup key={membre.id} membreId={membre.id} fullWidth>
        <Stack direction="row" alignItems="center" sx={{ p: 2, backgroundColor: "#F6F9FA", borderRadius: 1 }}>
          <Avatar
            variant="circular"
            alt={membre.first_name + " " + membre.last_name}
            src={membre.photo}
            sx={{ width: 42, height: 42, mr: 3 }}
          />
          <Stack direction="column" alignItems="flex-start" justifyContent="space-around">
            <Typography>{membre.first_name + " " + membre.last_name}</Typography>
            <Stack direction="row" alignItems="center">
              <Work sx={{ fontSize: "0.9rem" }} />
              <Typography sx={{ fontSize: "0.9rem", ml: 1 }}>{membre.poste_nom}</Typography>
            </Stack>
          </Stack>
          <ChevronRight sx={{ ml: "auto" }} />
        </Stack>
      </MembrePopup>
    ))
  }

  function MagasinContent() {
    if (magasinByIdQuery.isLoading) {
      return <Loader />
    }

    if (magasinByIdQuery.isError) {
      return (
        <Alert
          severity="error"
          variant="standard"
        >
          Une erreur est survenue
        </Alert>
      )
    }

    return (
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
            <Groups sx={{ fontSize: "2rem" }} />
            <Typography sx={{ fontSize: "1.2rem", ml: 2 }}>Les membres du magasin ({membresQuery.data?.length})</Typography>
          </Stack>
          <Stack spacing={3} sx={{ pr: 2, maxHeight: 400, overflowY: "scroll" }}>
            {MembresList()}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ backgroundColor: "#F6F9FA", p: 4 }}>
            <Typography variant="h6" fontWeight={600} mb={4}>{magasinByIdQuery.data?.nom}</Typography>

            <Divider sx={{ my: 5 }} />

            <Stack direction="row" alignItems="start" sx={{ mb: 2 }}>
              <Place color="primary" sx={{ fontSize: "1.2rem", mt: 0.6 }} />
              <Typography sx={{ fontSize: "1rem", ml: 2 }}>{magasinByIdQuery.data?.adresse || "-"}</Typography>
            </Stack>
            <Stack direction="row" alignItems="start" sx={{ mb: 2 }}>
              <Call color="primary" sx={{ fontSize: "1.2rem", mt: 0.6 }} />
              <Typography sx={{ fontSize: "1rem", ml: 2 }}>{magasinByIdQuery.data?.telephone || "-"}</Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={isOpen}
      onClose={closeFn}
    >
      <DialogContent sx={{ minWidth: 800 }}>
        {MagasinContent()}
      </DialogContent>
    </Dialog>
  )
}
