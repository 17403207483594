import useMarquesQuery from "hooks/queries/marques/useMarquesQuery"
import useVillesQuery from "hooks/queries/villes/useVillesQuery"
import { useEffect, useState } from "react"
import useMagasinsNoPaginationQuery from "./queries/magasins/useMagasinsNoPaginationQuery"
import useMembresNoPaginationQuery from "./queries/membres/useMembresNoPaginationQuery"
import usePostesQuery from "./queries/postes/usePostesQuery"

export type OptionType = { label: string, id: string }
export const emptyOption: OptionType = { label: "", id: "" }

export type Filter = {
  value: OptionType
  setValue: (value: OptionType) => void
  options: OptionType[]
}

export type DateFilter = {
  value: Date | null
  setValue: (value: Date | null) => void
}

type UseFiltersInput = () => void

type UseFiltersOutput = {
  villeFilter: Filter,
  marqueFilter: Filter,
  magasinFilter: Filter,
  membreFilter: Filter,
  posteFilter: Filter,
  dateStartFilter: DateFilter,
  dateEndFilter: DateFilter,
  congeStatutFilter: Filter,
  documentStatutFilter: Filter,
  documentTypeFilter: Filter,
  platformFilter: Filter,
}

export default function useFilters(onFilterChange: UseFiltersInput = () => {}): UseFiltersOutput {

  const [villeFilter, setVilleFilter] = useState(emptyOption)
  const [marqueFilter, setMarqueFilter] = useState(emptyOption)
  const [magasinFilter, setMagasinFilter] = useState(emptyOption)
  const [membreFilter, setMembreFilter] = useState(emptyOption)
  const [posteFilter, setPosteFilter] = useState(emptyOption)
  const [dateStartFilter, setDateStartFilter] = useState<Date | null>(null)
  const [dateEndFilter, setDateEndFilter] = useState<Date | null>(null)
  const [congeStatutFilter, setCongeStatutFilter] = useState(emptyOption)
  const [documentStatutFilter, setDocumentStatutFilter] = useState(emptyOption)
  const [documentTypeFilter, setDocumentTypeFilter] = useState(emptyOption)
  const [platformFilter, setPlatformFilter] = useState(emptyOption)

  // Run the function passed in the input when a filter changes
  // Most of the time, this will be a function that resets the pagination
  // Because when a filter is applied, the number of results will be lower
  // and the pagination will mostly be invalid, so we reset it to 1
  useEffect(() => onFilterChange(), [
    villeFilter,
    marqueFilter,
    magasinFilter,
    membreFilter,
    posteFilter,
    dateStartFilter,
    dateEndFilter,
    congeStatutFilter,
    documentStatutFilter,
    documentTypeFilter,
    platformFilter,
  ])

  const villesQuery = useVillesQuery()
  const marquesQuery = useMarquesQuery()
  const magasinsQuery = useMagasinsNoPaginationQuery()
  const membresQuery = useMembresNoPaginationQuery()
  const postesQuery = usePostesQuery()

  const villeFilterOptions = villesQuery.data?.map(ville => {
    return { label: ville.ville, id: ville.id.toString() }
  }) || [emptyOption]

  const marqueFilterOptions = marquesQuery.data?.map(marque => {
    return { label: marque.nom, id: marque.id.toString() }
  }) || [emptyOption]

  const magasinFilterOptions = magasinsQuery.data?.map(magasin => {
    return { label: magasin.nom, id: magasin.id.toString() }
  }) || [emptyOption]

  const membreFilterOptions = membresQuery.data?.map(membre => {
    return { label: `${membre.first_name} ${membre.last_name}`, id: membre.id.toString() }
  }) || [emptyOption]

  const posteFilterOptions = postesQuery.data?.map(poste => {
    return { label: poste.poste, id: poste.id.toString() }
  }) || [emptyOption]

  const congeSatutFilterOptions = [
    { label: "En attente", id: "en_attente" },
    { label: "Validé", id: "valide" },
    { label: "Rejeté", id: "rejete" },
  ]

  const documentStatutFilterOptions = [
    { label: "En attente", id: "en_attente" },
    { label: "Traité", id: "traite" },
  ]

  const documentTypeFilterOptions = [
    { label: "Attestaion de salaire", id: "attestaion_salaire" },
    { label: "Attestaion de travail", id: "attestaion_travail" },
    { label: "Certificat de travail", id: "certificat_travail" },
  ]

  const platformFilterOptions = [
    { label: "ANDROID", id: "Android" },
    { label: "IOS", id: "iOS" },
  ]

  return {
    villeFilter: {
      value: villeFilter,
      setValue: setVilleFilter,
      options: villeFilterOptions,
    },
    marqueFilter: {
      value: marqueFilter,
      setValue: setMarqueFilter,
      options: marqueFilterOptions,
    },
    magasinFilter: {
      value: magasinFilter,
      setValue: setMagasinFilter,
      options: magasinFilterOptions,
    },
    membreFilter: {
      value: membreFilter,
      setValue: setMembreFilter,
      options: membreFilterOptions,
    },
    posteFilter: {
      value: posteFilter,
      setValue: setPosteFilter,
      options: posteFilterOptions,
    },
    dateStartFilter: {
      value: dateStartFilter,
      setValue: setDateStartFilter,
    },
    dateEndFilter: {
      value: dateEndFilter,
      setValue: setDateEndFilter,
    },
    congeStatutFilter: {
      value: congeStatutFilter,
      setValue: setCongeStatutFilter,
      options: congeSatutFilterOptions,
    },
    documentStatutFilter: {
      value: documentStatutFilter,
      setValue: setDocumentStatutFilter,
      options: documentStatutFilterOptions,
    },
    documentTypeFilter: {
      value: documentTypeFilter,
      setValue: setDocumentTypeFilter,
      options: documentTypeFilterOptions,
    },
    platformFilter: {
      value: platformFilter,
      setValue: setPlatformFilter,
      options: platformFilterOptions,
    },
  }
}
