import { Autocomplete, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { isValid } from "date-fns"
import { DateFilter, emptyOption, Filter } from "hooks/useFilters"

type Props = {
  villeFilter?: Filter,
  marqueFilter?: Filter,
  magasinFilter?: Filter,
  membreFilter?: Filter,
  posteFilter?: Filter,
  congeStatutFilter?: Filter,
  documentStatutFilter?: Filter,
  documentTypeFilter?: Filter,
  platformFilter?: Filter,
  dateStartFilter?: DateFilter,
  dateEndFilter?: DateFilter,
}

export default function MainFilter(props: Props) {

  const {
    villeFilter,
    marqueFilter,
    magasinFilter,
    membreFilter,
    posteFilter,
    congeStatutFilter,
    documentStatutFilter,
    documentTypeFilter,
    platformFilter,
    dateStartFilter,
    dateEndFilter,
  } = props

  function VilleFilter() {
    if (villeFilter && villeFilter.options.length > 1) {
      return (
        <Autocomplete
          id="ville"
          sx={{ width: 300 }}
          options={villeFilter.options}
          onChange={(event, value) => villeFilter.setValue(value || emptyOption)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField {...params} label="Ville" />
          )}
        />
      )
    }
    return null
  }

  function MarqueFilter() {
    if (marqueFilter && marqueFilter.options.length > 1) {
      return (
        <Autocomplete
          id="marque"
          sx={{ width: 300 }}
          options={marqueFilter.options}
          onChange={(event, value) => marqueFilter.setValue(value || emptyOption)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField {...params} label="Marque" />
          )}
        />
      )
    }
    return null
  }

  function MagasinFilter() {
    if (magasinFilter && magasinFilter.options.length > 1) {
      return (
        <Autocomplete
          id="magasin"
          sx={{ width: 300 }}
          options={magasinFilter.options}
          onChange={(event, value) => magasinFilter.setValue(value || emptyOption)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField {...params} label="Magasin" />
          )}
        />
      )
    }
    return null
  }

  function MembreFilter() {
    if (membreFilter && membreFilter.options.length > 1) {
      return (
        <Autocomplete
          id="membre"
          sx={{ width: 300 }}
          options={membreFilter.options}
          onChange={(event, value) => membreFilter.setValue(value || emptyOption)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField {...params} label="Membre" />
          )}
        />
      )
    }
    return null
  }

  function PosteFilter() {
    if (posteFilter && posteFilter.options.length > 1) {
      return (
        <Autocomplete
          id="poste"
          sx={{ width: 300 }}
          options={posteFilter.options}
          onChange={(event, value) => posteFilter.setValue(value || emptyOption)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField {...params} label="Poste" />
          )}
        />
      )
    }
    return null
  }

  function DocumentTypeFilter() {
    if (documentTypeFilter && documentTypeFilter.options.length > 1) {
      return (
        <Autocomplete
          id="type"
          sx={{ width: 300 }}
          options={documentTypeFilter.options}
          onChange={(event, value) => documentTypeFilter.setValue(value || emptyOption)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField {...params} label="Type de document" />
          )}
        />
      )
    }
    return null
  }

  function PlatformFilter() {
    if (platformFilter && platformFilter.options.length > 1) {
      return (
        <Autocomplete
          id="type"
          sx={{ width: 300 }}
          options={platformFilter.options}
          onChange={(event, value) => platformFilter.setValue(value || emptyOption)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField {...params} label="Platforme" />
          )}
        />
      )
    }
    return null
  }

  function DateStartFilter() {
    if (dateStartFilter) {
      return (
        <DatePicker
          label="Date début"
          maxDate={dateEndFilter?.value || null}
          value={dateStartFilter.value}
          onChange={(value) => {
            if (isValid(value) || value === null) {
              dateStartFilter.setValue(value)
            }
          }}
          renderInput={params => (
            <TextField {...params} />
          )}
        />
      )
    }
    return null
  }

  function DateEndFilter() {
    if (dateEndFilter) {
      return (
        <DatePicker
          label="Date fin"
          minDate={dateStartFilter?.value}
          value={dateEndFilter.value}
          onChange={(value) => {
            if (isValid(value) || value === null) {
              dateEndFilter.setValue(value)
            }
          }}
          renderInput={params => (
            <TextField {...params} />
          )}
        />
      )
    }
    return null
  }

  function CongeStatutFilter() {
    if (congeStatutFilter && congeStatutFilter.options.length > 1) {
      return (
        <Autocomplete
          id="type"
          sx={{ width: 300 }}
          options={congeStatutFilter.options}
          onChange={(event, value) => congeStatutFilter.setValue(value || emptyOption)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField {...params} label="Type de document" />
          )}
        />
      )
    }
    return null
  }

  function DocumentStatutFilter() {
    if (documentStatutFilter && documentStatutFilter.options.length > 1) {
      return (
        <Autocomplete
          id="type"
          sx={{ width: 300 }}
          options={documentStatutFilter.options}
          onChange={(event, value) => documentStatutFilter.setValue(value || emptyOption)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField {...params} label="Type de document" />
          )}
        />
      )
    }
    return null
  }

  return (
    <>
      {VilleFilter()}

      {MarqueFilter()}

      {MagasinFilter()}

      {MembreFilter()}

      {PosteFilter()}

      {DocumentTypeFilter()}

      {PlatformFilter()}

      {DateStartFilter()}

      {DateEndFilter()}

      {CongeStatutFilter()}

      {DocumentStatutFilter()}
    </>
  )
}
