import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_CREATE_PLANNING } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type PlanningDay = {
  date_jour: string
  jour: string
  shift_matin_heure_debut?: string | null
  shift_matin_heure_fin?: string | null
  shift_apresmidi_heure_debut?: string | null
  shift_apresmidi_heure_fin?: string | null
  si_jour_repos: boolean
  type_repos?: string | null
}

export type UpdatePlanningRequest = {
  id: number,
  planing_jours: PlanningDay[]
}

export type UpdatePlanningResponse = {
  id: number
}

export type UpdatePlanningError = {
  planning_duplication_error?: any,
  semaine_started_error?: any,
  detail: "Authentication credentials were not provided." | "semaine_started_error" | undefined
}

export default async function updatePlanning(params: UpdatePlanningRequest) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "PATCH",
    url: `${API_CREATE_PLANNING}${params.id}/`,
    data: params
  })

  return response.data as UpdatePlanningResponse
}
