import { Assignment, AssignmentTurnedIn } from "@mui/icons-material"
import { Box, Tab, Tabs } from "@mui/material"
import { ReactNode, SyntheticEvent, useState } from "react"
import { useDocumentTitle } from "usehooks-ts"
import DocumentsAttente from "./components/DocumentsAttente"
import DocumentsTraites from "./components/DocumentsTraites"

type Props = {
  children?: ReactNode
  index: number
  value: number
}

function TabPanel({ children, value, index }: Props) {
  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box>{children}</Box>
      )}
    </div>
  )
}

export default function Documents() {

  useDocumentTitle("Mafranchise | Documents")

  const [value, setValue] = useState(0)

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 8 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Demandes en attente" icon={<Assignment />} iconPosition="start" />
          <Tab label="Demandes traitées" icon={<AssignmentTurnedIn />} iconPosition="start" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <DocumentsAttente />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DocumentsTraites />
      </TabPanel>
    </Box>
  )
}
