import { useQueryClient, useMutation } from "@tanstack/react-query"
import updateAbsence, { UpdateAbsenceRequest } from "api/assiduite/updateAbsence"
import { KEY_ABSENCES } from "constants/queryKeys"
import toast from "react-hot-toast"

export default function useUpdateAbsenceMutation() {

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: UpdateAbsenceRequest) => updateAbsence(data),
    onMutate: () => {
      toast.loading("Traitement en cours..", {
        id: "demandeAbsence",
      })
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [KEY_ABSENCES, { statut: "en_attente" }] })

      if (response.statut === "justifie") {
        await queryClient.invalidateQueries({ queryKey: [KEY_ABSENCES, { statut: "justifie" }] })
        toast.success("Demande justifiée avec succès", {
          id: "demandeAbsence",
        })
      } else if (response.statut === "non_justifie") {
        await queryClient.invalidateQueries({ queryKey: [KEY_ABSENCES, { statut: "non_justifie" }] })
        toast.success("Demande rejetée avec succès", {
          id: "demandeAbsence",
        })
      }
    },
    onError: () => {
      toast.error("Une erreur est survenue", {
        id: "demandeAbsence",
      })
    },
  })
}
